import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';

import { QUICK_FILTER_MENU_IDS, QUICK_FILTER_MENU_LABELS } from './constants';
import SearchableDropdown from './searchable-dropdown';

interface MobileAssigneesSectionProps {
  selectedItems: Map<string, string>;
  selectedGroups: Map<string, string>;
  setSelectedGroups: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  selectedUsers: Map<string, string>;
  setSelectedUsers: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  handleSearchGroups: (searchText: string) => void;
  handleSearchUsers: (searchText: string) => void;
  handleSelectItem: (selection: { id: string, label: string }) => void;
  handleSelectMyCases: (selection: { id: string, label: string }) => void;
  disabled?: boolean;
  users?: Array<{ id: string; label?: string, avatar?: React.ReactNode }>;
  groups?: Array<{ id: string; label?: string, avatar?: React.ReactNode }>;
}

interface ButtonProps {
  id: string;
  label: string;
  onClick: (selection: { id: string, label: string }) => void;
  selectedItems: Map<string, string>;
  disabled?: boolean;
}

/**
 * These styles are used for the quick filter in the mobile width view
 * copying the design of the tags buttons also used in the FilterTray.
 * In a later ticket, we will change all the components colors in this
 * tray to use Chakra and it's themes.
 * @param disabled - Whether the button is disabled
 * @param isSelected - Whether the button is selected
 * @returns The styles for the quick filter button
 */
const getButtonStyles = (disabled: boolean, isSelected: boolean) => {
  const buttonStyles = {
    color: '#495A6A',
    borderColor: 'gray.200',
    fontWeight: 'normal',
    backgroundColor: '#F2F3F7',
    _hover: {
      backgroundColor: '#F2F3F7',
    },
  };
  if (disabled) {
    buttonStyles.borderColor = 'gray.200';
    buttonStyles.color = 'gray.200';
    buttonStyles.backgroundColor = '#F2F3F7';
    buttonStyles._hover.backgroundColor = '#F2F3F7';
    return buttonStyles;
  }
  if (isSelected) {
    buttonStyles.fontWeight = '600';
    buttonStyles.borderColor = '#36383D';
    buttonStyles.color = '#242627';
    buttonStyles.backgroundColor = 'white';
    buttonStyles._hover.backgroundColor = 'white';
    return buttonStyles;
  }
  return buttonStyles;
};

// Reusable Button Component
const QuickFilterButton: React.FC<ButtonProps> = ({
  id,
  label,
  onClick,
  selectedItems,
  disabled = false,
}) => {
  const isSelected = selectedItems.has(id);
  return (
    <Button
      type="button"
      onClick={() => onClick({ id, label })}
      borderRadius="16px"
      padding="5px 14px"
      marginRight="8px"
      marginBottom="12px"
      fontSize="0.75rem"
      border="1px solid transparent"
      disabled={disabled}
      sx={getButtonStyles(disabled, isSelected)}
    >
      {label}
    </Button>
  );
};

const MobileAssigneesSection: React.FC<MobileAssigneesSectionProps> = ({
  selectedItems,
  handleSelectItem,
  selectedGroups,
  setSelectedGroups,
  selectedUsers,
  setSelectedUsers,
  users = [],
  groups = [],
  handleSearchGroups,
  handleSearchUsers,
  handleSelectMyCases,
  disabled,
}) => (
  <Box>
    <Flex flexWrap="wrap">
      <QuickFilterButton
        id={QUICK_FILTER_MENU_IDS.myChats}
        label={QUICK_FILTER_MENU_LABELS.primaryOpLabel}
        onClick={handleSelectMyCases}
        selectedItems={selectedItems}
        disabled={disabled}
      />
      <QuickFilterButton
        id={QUICK_FILTER_MENU_IDS.secondaryOperatorIds}
        label={QUICK_FILTER_MENU_LABELS.secondaryOpsLabel}
        onClick={handleSelectMyCases}
        selectedItems={selectedItems}
        disabled={disabled}
      />
      <QuickFilterButton
        id={QUICK_FILTER_MENU_IDS.unassigned}
        label="Unassigned"
        onClick={handleSelectItem}
        selectedItems={selectedItems}
        disabled={disabled}
      />
    </Flex>
    <Flex direction="row" gap={4} padding={2} width="100%">
      <SearchableDropdown
        items={users}
        selectedItemsMap={selectedUsers}
        setSelectedItems={setSelectedUsers}
        handleSearch={handleSearchUsers}
        disabled={disabled}
        defaultLabel="Users"
        entityLabel="Users"
      />
      <SearchableDropdown
        items={groups}
        selectedItemsMap={selectedGroups}
        setSelectedItems={setSelectedGroups}
        handleSearch={handleSearchGroups}
        disabled={disabled}
        defaultLabel="Groups"
        entityLabel="Groups"
      />
    </Flex>
  </Box>
);

export default MobileAssigneesSection;
