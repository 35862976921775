import {
  Box, chakra, IconButton, Icon,
} from '@chakra-ui/react';
import { CloseIcon } from '@himarley/unity';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Define styles for old and new variants
const datePickerStyles = {
  variants: {
    old: {
      '.react-datepicker__input-container input': {
        width: '180px',
        height: '21px',
        padding: 0,
        border: 'none',
        borderBottom: '1px solid',
        borderRadius: 0,
        borderColor: 'inherit',
        background: 'inherit',
        fontSize: 'sm',
        _focus: {
          outline: 'none',
          borderColor: 'blue.500',
        },
      },
    },
    new: {
      '.react-datepicker__input-container input': {
        width: '100%',
        height: '40px',
        padding: '0.5rem 2.5rem 0.5rem 1rem',
        borderRadius: 'md',
        border: '1px solid',
        borderColor: 'inherit',
        background: 'inherit',
        fontSize: 'md',
        _focus: {
          borderColor: 'blue.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
          zIndex: 1,
        },
      },
    },
  },
  baseStyle: {
    '.react-datepicker-wrapper': {
      width: '100%',
    },
    '.react-datepicker': {
      fontFamily: 'inherit',
      borderColor: 'gray.200',
      display: 'flex !important',
    },
    '.react-datepicker__header': {
      bg: 'gray.50',
      borderBottomColor: 'gray.200',
    },
    '.react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day': {
      color: 'gray.700',
    },
    '.react-datepicker__day': {
      _hover: {
        bg: 'gray.100',
      },
    },
    '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
      bg: 'blue.500',
      color: 'white',
      _hover: {
        bg: 'blue.600 !important',
      },
    },
    '.react-datepicker__day--disabled': {
      color: 'gray.400 !important',
      _hover: {
        bg: 'inherit !important',
      },
    },
    '.react-datepicker__time-container': {
      borderLeft: '1px solid var(--chakra-colors-gray-200) !important',
      width: '100px !important',
    },
    '.react-datepicker__time-box': {
      width: '100% !important',
    },
    '.react-datepicker__time-list-item--selected': {
      bg: 'blue.500 !important',
    },
    '.react-datepicker__navigation': {
      top: '5px',
    },
    '.react-datepicker__navigation--previous': {
      left: '8px',
    },
    '.react-datepicker__navigation--next': {
      left: '200px',
    },
  },
};

const StyledDatePickerWrapper = chakra(Box, { baseStyle: datePickerStyles.baseStyle });

export interface DateInputProps {
  id?: string;
  selectedDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  includeTime?: boolean;
  dateLimit?: 'past' | 'future';
  handleClear?: () => void;
  timeIntervals?: number;
  placeholderText?: string;
  useOldStyle?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const DateInput = ({
  id,
  selectedDate,
  handleDateChange,
  includeTime = false,
  dateLimit,
  handleClear,
  timeIntervals = 15,
  placeholderText = 'MM/DD/YYYY',
  useOldStyle = false,
  disabled = false,
  minDate,
  maxDate,
}: DateInputProps) => {
  const styles = useOldStyle ? datePickerStyles.variants.old : datePickerStyles.variants.new;

  return (
    <StyledDatePickerWrapper position="relative" sx={styles}>
      <DatePicker
        id={id}
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect={includeTime}
        dateFormat={includeTime ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'}
        timeFormat="h:mm aa"
        timeIntervals={timeIntervals}
        maxDate={maxDate || (dateLimit === 'past' ? new Date() : undefined)}
        minDate={minDate || (dateLimit === 'future' ? new Date() : undefined)}
        placeholderText={placeholderText}
        autoComplete="off"
        timeCaption="Time"
        showTimeSelectOnly={false}
        popperPlacement="top-end"
        disabled={disabled}
      />
      {!useOldStyle && selectedDate && handleClear && (
        <IconButton
          aria-label="Clear date"
          icon={<Icon as={CloseIcon} boxSize={4} />}
          size="sm"
          variant="ghost"
          position="absolute"
          right="2"
          top="50%"
          transform="translateY(-50%)"
          onClick={handleClear}
        />
      )}
    </StyledDatePickerWrapper>
  );
};

export default DateInput;
