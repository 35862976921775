/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import withLoading from '../../HigherOrderComponents/withLoading';
import { getLookerUrl } from '../../../actions/analytics';
import { operatorType } from '../../../models/marleyTypes';
import './LookerDashboard.less';
import selector from './selector';

class LookerDashboard extends React.Component {
  componentDidMount() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = timezone.replace(/\//ig, '-');
    this.props.getLookerUrl(this.props.dashboardName, timezone);
  }

  render() {
    return (
      <div className="analytics-dashboard">
        <iframe
          src={this.props.lookerUrl}
          style={{
            border: '0', position: 'absolute', width: '97%', height: '90%',
          }}
        />
      </div>
    );
  }
}
const LookerDashboardWithLoading = withLoading(LookerDashboard, { type: operatorType });
export default connect(
  (state) => selector(state),
  { getLookerUrl, operatorType },
)(LookerDashboardWithLoading);
