import { Icon, Tooltip, Heading } from '@chakra-ui/react';
import {
  Avatar, Accordion, Divider,
  InfoCircleIcon, CheckmarkFilledIcon,
} from '@himarley/unity';
import React, { useState, useCallback, useMemo } from 'react';

import Menu from '@app/components/chakra/menu';
import SearchBar from '@app/components/SearchBar/SearchBar';
import BinocularsIcon from '@images/icons/binoculars.svg';

import styles from './LinesOfBusiness.module.less';
import LinesOfBusinessRows from './LinesOfBusinessRows';

const sortDropdownList: {
  id: string;
  label: string;
  query: boolean;
  sort: (brands: string[]) => string[];
  onClick?: () => void;
  rightIcon?: React.FC;
}[] = [
  {
    id: 'az',
    label: 'A-Z',
    query: true,
    sort: (brands: string[]) => brands.sort((a, b) => a.localeCompare(b)),
  },
  {
    id: 'za',
    label: 'Z-A',
    query: false,
    sort: (brands: string[]) => brands.sort((a, b) => b.localeCompare(a)),
  },
];

interface LinesOfBusinessProps {
  organization: {
    branding: string[];
    linesOfBusiness: {
      branding: string;
      id: string;
      name: string;
      description: string;
      active: boolean;
    }[];
  };
  setSelectedLob: (lob: unknown) => void;
}

const LinesOfBusiness: React.FC<LinesOfBusinessProps> = ({ organization, setSelectedLob }) => {
  const [sortType, setSortType] = useState(sortDropdownList[0]);
  const [searchText, setSearchText] = useState('');
  const [isAllExpanded, setIsAllExpanded] = useState(true);

  const brandToLinesOfBusinessMap = useMemo(() => {
    const map = new Map<string, typeof organization.linesOfBusiness>(
      organization?.branding?.map((brand) => [brand, []]),
    );

    organization?.linesOfBusiness?.forEach((lineOfBusiness) => {
      if (map.has(lineOfBusiness?.branding)) {
        map.set(lineOfBusiness.branding, [
          ...(map.get(lineOfBusiness?.branding) || []),
          lineOfBusiness,
        ]);
      }
    });

    return map;
  }, [organization]);

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e?.target?.value || '');
  };

  const renderRightAccordionContent = useCallback(
    (brandName: string) => {
      if (brandToLinesOfBusinessMap.has(brandName)
        && brandToLinesOfBusinessMap.get(brandName)?.length
      ) {
        return `${
          brandToLinesOfBusinessMap.get(brandName)?.length
        } Lines of Business`;
      }
      return (
        <div className={styles.noLobWrap}>
          <span>No Lines Added</span>
          <Tooltip hasArrow label="This brand does not have any lines of business. System Admins must add lines of business in your Org. Settings first.">
            <Icon as={InfoCircleIcon} />
          </Tooltip>
        </div>
      );
    },
    [brandToLinesOfBusinessMap],
  );

  const brands = useMemo(
    () => sortType?.sort(
      Array.from(brandToLinesOfBusinessMap.keys())
        .filter((brand) => brand.toUpperCase().includes(searchText.toUpperCase())),
    ),
    [brandToLinesOfBusinessMap, searchText, sortType],
  );

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;
  const infoMessage = isWelcomeFlowRefactorEnabled ? 'Configure welcome messages per line of business.' : 'Configure opt-in message flows per line of business.';

  const finalSortDropdownList = useMemo(() => sortDropdownList.map((sortOption) => ({
    ...sortOption,
    onClick: () => setSortType(sortOption),
    rightIcon: sortOption.id === sortType.id ? CheckmarkFilledIcon : undefined,
  })), [sortType]);

  return (
    <section className="brandsWrap" data-testId="linesOfBusinessPage">
      <p>{infoMessage}</p>
      {organization?.branding?.length > 0 ? (
        <>
          <div className="topBar">
            <h3>
              {`${brands.length} ${brands.length === 1 ? 'Brand' : 'Brands'}`}
            </h3>
            <div className="searchSort">
              <SearchBar
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                placeholder="Search Brands..."
                onChange={onSearchTextChange}
              />
              <Menu
                id="sort-brands"
                options={finalSortDropdownList}
                defaultLabel={`Sort: ${sortType.label}`}
                menuButtonProps={{ variant: 'ghost' }}
                matchWidth
              />
              <button
                type="button"
                onClick={() => setIsAllExpanded(!isAllExpanded)}
                className={styles.expandCollapseBtn}
              >
                {isAllExpanded ? 'Collapse All' : 'Expand All'}
              </button>
            </div>
          </div>
          <div className={styles.brandListWrap}>
            {brands.length === 0 && (
              <div className="none-found-notification">
                <div className="none-found-icon">
                  <BinocularsIcon />
                </div>
                <div>We&apos;re looking, but no Brands found.</div>
              </div>
            )}
            {brands?.map((brandName) => (
              <Accordion
                isExpanded={
                  !!brandToLinesOfBusinessMap.get(brandName)?.length
                  && isAllExpanded
                }
                showExpandCollapseButton={
                  !!brandToLinesOfBusinessMap.get(brandName)?.length
                }
                disabled={!brandToLinesOfBusinessMap.get(brandName)?.length}
                key={brandName}
                leftContent={(
                  <div className={styles.brandName}>
                    <Avatar
                      name={brandName}
                      isCustomer
                      className={styles.brandAvatar}
                    />
                    <Heading size="sm" mb={0}>
                      <b>{brandName}</b>
                    </Heading>
                  </div>
                )}
                rightContent={renderRightAccordionContent(brandName)}
              >
                <LinesOfBusinessRows
                  lobs={brandToLinesOfBusinessMap?.get(brandName) || []}
                  setSelectedLob={setSelectedLob}
                />
              </Accordion>
            ))}
          </div>
        </>
      ) : (
        <div className="noBrandsWrap">
          <p>Your organization doesn’t have any brands yet.</p>
        </div>
      )}
      <Divider />
    </section>
  );
};

export default LinesOfBusiness;
