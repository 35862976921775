import {
  Avatar, AvatarGroup, Box, Tooltip,
} from '@chakra-ui/react';
import React from 'react';

import { ROLE_ENDUSER } from '@app/constants/general';
import { type User } from '@app/types/api/user';

interface ParticipantCirclesProps {
  participants: User[]
  onClick: () => void
}

const ParticipantCircles: React.FC<ParticipantCirclesProps> = (
  { participants, onClick },
) => (
  <Tooltip hasArrow label="View Participants">
    <Box
      onClick={onClick}
      cursor="pointer"
    >
      <AvatarGroup data-testid="participant-circles">
        {participants.map((participant) => {
          const {
            imageUrl,
            name,
            role,
            id,
          } = participant;

          return (
            <Avatar
              data-testid="participant-circle"
              id={id}
              key={`participantCircle-${id}`}
              bg={role === ROLE_ENDUSER ? 'marleyBlue.500' : 'marleyRed.500'}
              color="white"
              size="sm"
              name={name}
              src={imageUrl}
              getInitials={(avatarName) => avatarName.split(' ').map((n) => n.slice(0, 1)).join('')}
            />
          );
        })}
      </AvatarGroup>
    </Box>
  </Tooltip>
);

export default ParticipantCircles;
