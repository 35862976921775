import {
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Icon,
  SimpleGrid,
  HStack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Modal, TrashIcon, InfoCircleIcon } from '@himarley/unity';
import _ from 'lodash';
import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { connect, ConnectedProps, useSelector } from 'react-redux';

import {
  updateProfile as updateProfileAction,
  getListOfTitles as getListOfTitlesAction,
} from '@app/actions/profile';
import { uploadProfileImage as uploadProfileImageAction } from '@app/actions/profileImage';
import AutoComplete from '@app/components/chakra/fields/auto-complete';
import TextField from '@app/components/chakra/fields/text-field';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import UserPhoto2 from '@app/components/UserPhoto2/UserPhoto2';
import { TITLE_ENABLED, TITLE_EDIT_ONLY_ADMIN } from '@app/constants/permissions';
import { cleanPhone, formatPhoneNumberV2 } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';

import './GeneralInfo.less';

const mapDispatchToProps = {
  updateProfile: updateProfileAction,
  uploadProfileImage: uploadProfileImageAction,
  getListOfTitles: getListOfTitlesAction,
};

const mapStateToProps = ({
  profile,
  ui,
  auth,
}: StateType) => ({
  ...profile,
  request: _.get(ui, 'requests.profileImage', {}),
  authUserId: _.get(auth, 'user._id'),
  isAdmin: auth?.user?.roles?.includes('ADMIN') || false,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GeneralInfoProps = PropsFromRedux & {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  title: string;
  organizationId: string;
  contactNumber: string;
  imageUrl: string;
  readonly: boolean;
  isAdmin: boolean;
  request: unknown;
  organization: {
    identityProvider?: string;
    jit?: boolean;
  };
  name?: string;
};

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phoneNumber: string;
  contactNumber: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  uploadProfileImage,
  readonly,
  isAdmin = false,
  getListOfTitles = () => { },
  organization = {},
  request = {},
  organizationId = '',
  firstName: propsFirstName,
  lastName: propsLastName,
  phoneNumber: propsPhoneNumber,
  contactNumber: propsContactNumber,
  imageUrl: propsImageUrl,
  email: propsEmail,
  title: propsTitle = '',
  _id,
  updateProfile,
  name,
}) => {
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();
  const toastIdRef = useRef<null | string | number>(null);
  const listTitles = useSelector((state: StateType) => state.operators?.listOfTitles || []);

  const methods = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: {
      firstName: propsFirstName,
      lastName: propsLastName,
      email: propsEmail,
      title: propsTitle,
      phoneNumber: formatPhoneNumberV2(propsPhoneNumber || ''),
      contactNumber: propsContactNumber,
    },
  });

  useEffect(() => {
    getListOfTitles();
  }, [getListOfTitles, readonly]);

  useEffect(() => {
    methods.reset({
      firstName: propsFirstName,
      lastName: propsLastName,
      email: propsEmail,
      title: propsTitle,
      phoneNumber: formatPhoneNumberV2(propsPhoneNumber || ''),
      contactNumber: propsContactNumber,
    });
  }, [
    propsContactNumber,
    propsEmail,
    propsFirstName,
    propsLastName,
    propsPhoneNumber,
    propsTitle,
    readonly,
    methods,
  ]);

  const handleSubmit = async (data: IFormInput) => {
    toastIdRef.current = toast({
      description: 'Saving profile...',
      status: 'loading',
      duration: null,
      variant: 'left-accent',
    });

    try {
      const profile = {
        _id,
        ...data,
        phoneNumber: cleanPhone(data.phoneNumber),
        organizationId,
        oldEmail: propsEmail,
      };

      await updateProfile(profile, toast, toastIdRef);
    } catch (error) {
      toast.update(toastIdRef.current, {
        description: 'Failed to save profile',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = _.last(e.target.files);
      if (file) {
        toastIdRef.current = toast({
          description: 'Uploading file...',
          status: 'loading',
          duration: null,
          variant: 'left-accent',
        });
        uploadProfileImage(file, toast, toastIdRef);
      }
    },
    [uploadProfileImage, toast],
  );

  const removePhoto = useCallback(() => {
    const profile = {
      _id,
      email: propsEmail || '',
      // Required to not have chat server auto change the oktaUserId
      // from bulk upload easter egg logic
      oldEmail: propsEmail,
      ...{
        firstName: propsFirstName,
        lastName: propsLastName,
        phoneNumber: propsPhoneNumber,
        contactNumber: propsContactNumber,
      },
      imageUrl: null,
    };
    updateProfile(profile);
    setShowModal(false);
  }, [_id,
    propsContactNumber,
    propsFirstName,
    propsLastName,
    propsPhoneNumber,
    propsEmail,
    updateProfile,
  ]);

  const userName = name || `${propsFirstName} ${propsLastName}`;

  const isFinishedLoading = !_.get(request, 'requesting') && _.get(request, 'success');

  const renderGeneralInfo = useMemo(
    () => (
      <VStack gap="6">
        <VStack gap="2">
          <div className="changePhotoWrap">
            <UserPhoto2
              imageUrl={propsImageUrl}
              isFinishedLoading={!!isFinishedLoading}
              id={_id}
              name={userName}
              className="user-photo-preview"
              testId="profile-page"
              isCustomer={false}
            />
            {propsImageUrl
              && (
                <IconButton
                  isRound
                  className="iconDeleteBtn"
                  colorScheme="gray"
                  onClick={() => setShowModal(true)}
                  color="gray.500"
                  icon={<Icon as={TrashIcon} />}
                  data-testid="remove-avatar-image"
                  size="sm"
                  aria-label=""
                />
              )}
          </div>
          <Tooltip label="Image must be a jpg or png under 5mb">
            <Button colorScheme="gray" variant="outline" className="file-input-wrapper" size="sm">
              Change Photo
              <input
                id="files"
                type="file"
                className="hidden-file-input"
                onChange={handleFileSelectChange}
                onClick={
                  // Allows onChange to run when exact same file is uploaded again
                  (event) => {
                    const target = event.target as HTMLInputElement;
                    target.value = '';
                  }
                }
                data-testId="change-avatar-button"
                title="" // Hides tooltip that browsers show for selected file
              />
            </Button>
          </Tooltip>
        </VStack>
        <VStack gap="3" mb="10" align="center">
          <Heading size="md" mb="0" height="40px">{userName}</Heading>
          {propsTitle && (
            <Heading size="sm" mb="0" color="gray.500">{propsTitle}</Heading>
          )}
        </VStack>
      </VStack>
    ),
    [propsImageUrl, isFinishedLoading, _id, userName, handleFileSelectChange, propsTitle],
  );

  const renderModal = useMemo(
    () => (
      <Modal
        show={showModal}
        title="Are you sure you want to delete your image?"
        showCloseButton
        toggleModal={() => setShowModal(false)}
      >
        <Text mb="10">
          You cannot undo this action.
          You may upload a new image by clicking on the &quot;Change photo&quot; link.
        </Text>
        <Flex justify="end">
          <Button
            colorScheme="red"
            onClick={removePhoto}
            data-testid="remove-avatar-image-confirmation"
          >
            Delete Photo
          </Button>
        </Flex>
      </Modal>
    ),
    [removePhoto, showModal],
  );

  const isAbleToEditEmail = useMemo(
    () => {
      const isSSOUser = organization?.identityProvider === 'FEDERATED';
      const isJITUser = organization?.jit;
      const { EDIT_EMAIL_ENABLED } = process.env;
      return isAdmin && ((isSSOUser && !isJITUser && EDIT_EMAIL_ENABLED) || !isSSOUser);
    },
    [organization?.identityProvider, organization?.jit, isAdmin],
  );

  const isTitleEnabled = usePermissionVerify({ neededPermissions: [TITLE_ENABLED] });
  const isTitleEditAdminOnly = usePermissionVerify({
    neededPermissions: [TITLE_EDIT_ONLY_ADMIN],
  });

  const hasAccessToEditTitles = useMemo(() => {
    const { hasAccess: adminOnly } = isTitleEditAdminOnly;
    return adminOnly ? isAdmin : true;
  }, [isTitleEditAdminOnly, isAdmin]);

  const canEditTitle = useMemo(
    () => isTitleEnabled.hasAccess && hasAccessToEditTitles,
    [isTitleEnabled, hasAccessToEditTitles],
  );

  return (
    <>
      {renderModal}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} className="general-info">
          {renderGeneralInfo}
          {isAbleToEditEmail && (
            <HStack align="center" mb="10" gap="2">
              <Icon as={InfoCircleIcon} />
              <Text color="gray.500">Changing a user email will log them out when you click Save</Text>
            </HStack>
          )}
          <SimpleGrid columns={[null, 2]} spacing="10" mb="4">
            <TextField<IFormInput>
              name="firstName"
              label="First Name"
              registerOptions={{ required: { value: true, message: 'First Name is Required' } }}
            />
            <TextField<IFormInput>
              name="lastName"
              label="Last Name"
              registerOptions={{ required: { value: true, message: 'Last Name is Required' } }}
            />
            <TextField<IFormInput>
              name="email"
              label="Email"
              isDisabled={!isAbleToEditEmail}
              registerOptions={{
                required: { value: true, message: 'Email is Required' },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid Email Format',
                },
              }}
            />
            {canEditTitle && (
              <AutoComplete<IFormInput>
                id="title"
                name="title"
                label="Title"
                options={listTitles}
              />
            )}
            <TextField<IFormInput>
              name="phoneNumber"
              label="Phone Number"
              formatValue={formatPhoneNumberV2}
              leftElement="+1"
              registerOptions={{
                minLength: { value: 14, message: 'Phone number must be 10 digits' },
                maxLength: { value: 14, message: 'Phone number must be 10 digits' },
              }}
            />
            <TextField<IFormInput>
              name="contactNumber"
              label="Office Phone Number"
            />
          </SimpleGrid>
          <Divider my="10" />
          <Flex gap={4} justify="flex-end" align="center">
            <Button
              type="submit"
              isDisabled={!methods.formState.isValid}
              isLoading={methods.formState.isSubmitting}
              data-testid="profile-save-button"
            >
              Save Profile
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};

export default connector(GeneralInfo);
