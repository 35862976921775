import { Button, Icon } from '@chakra-ui/react';
import { AddCircleIcon, EditIcon } from '@himarley/unity';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CreateCaseModal from './create-case-modal';
import useScreenSize from '../Hooks/use-screen-size';

interface CreateCaseButtonProps {
  buttonText?: string;
  size?: 'sm' | 'md' | 'lg';
}

const CreateCaseButton = ({ buttonText, size = 'md' }: CreateCaseButtonProps) => {
  const history = useHistory();
  const location = useLocation();
  const isSmallScreen = useScreenSize();

  if (isSmallScreen) {
    return (
      <>
        <Button
          data-testid="create-case-button"
          size={size}
          onClick={() => {
            history.push(`${location.pathname}/create`);
          }}
          bg="transparent"
          sx={{
            _hover: {
              bg: 'transparent',
            },
          }}
        >
          <Icon
            as={EditIcon}
            className="edit-icon"
            sx={{ color: 'blue.60 !important' }}
          />
        </Button>
        {location.pathname.includes('create') && (
        <CreateCaseModal />
        )}
      </>
    );
  }

  return (
    <>
      <Button
        data-testid="create-case-button"
        leftIcon={<Icon as={AddCircleIcon} />}
        size={size}
        onClick={() => {
          history.push(`${location.pathname}/create`);
        }}
      >
        {buttonText || 'Create Case'}
      </Button>
      {location.pathname.includes('create') && (
        <CreateCaseModal />
      )}
    </>
  );
};

export default CreateCaseButton;
