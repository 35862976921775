import _ from 'lodash';
import * as commonReducer from './common';

export const initialState = {
  list: [],
  listById: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EDIT_GROUP': {
      const groupId = _.get(action, 'payload.body.group._id', {});
      const name = _.get(action, 'payload.body.group.name');
      const description = _.get(action, 'payload.body.group.description');
      const archived = _.get(action, 'payload.body.group.archived');
      let list = _.get(state, 'list', []).slice();

      list = list.map((g) => {
        const isModifiedGroup = g.id === groupId;
        const doModify = isModifiedGroup || archived;
        if (doModify) {
          return ({
            ...g,
            members: archived ? g.members.map((m) => ({
              ...m,
              groups: _.difference(m.groups, [groupId]),
            })) : g.members,
            name: isModifiedGroup ? name : g.name,
            description: isModifiedGroup ? description : g.description,
          });
        }
        return g;
      });

      return {
        ...state,
        list,
      };
    }
    case 'GET_USER_ROLES': {
      let userRoles = _.get(action, 'payload', []);
      let list = _.get(state, 'list', []).slice();
      userRoles = userRoles.map((user) => (user.Item !== undefined ? user.Item : {}));
      list = list.map((group) => ({
        ...group,
        members: _.get(group, 'members', []).map((member) => ({
          ...member,
          roles: _.get(_.find(userRoles, (r) => r.identityId === member.id), 'groups', []),
        })),
        leads: _.get(group, 'leads', []).map((lead) => ({
          ...lead,
          roles: _.get(_.find(userRoles, (r) => r.identityId === lead.id), 'groups', []),
        })),
      }));
      return { ...state, list };
    }
    case 'UPDATE_USER_GROUPS': {
      const { id, groups: newGroups, user } = action.payload;
      let list = _.get(state, 'list', []).slice();

      list = list.map((group) => {
        const memberIndex = group.members.findIndex((m) => m.id === id || m._id === id);
        const updatedGroup = { ...group };

        if (memberIndex !== -1) {
          // Remove member if they no longer belong to this group
          if (!newGroups?.includes(group.id)) {
            updatedGroup.members = group.members.filter((_, index) => index !== memberIndex);
          }
        } else if (newGroups?.includes(group.id)) {
          // Add member if they now belong to this group
          updatedGroup.members = [...group.members, user];
        }

        return updatedGroup;
      });

      return {
        ...state,
        list,
      };
    }
    default:
      return state;
  }
};

export default commonReducer.reducer('GROUP', reducer);
