import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { jobPutRequest } from '@app/actions/job';
import AssignUser from '@app/components/chakra/action-menus/assign-user';
import TableCell from '@app/components/elements/table/TableCell/TableCell';
// eslint-disable-next-line import/no-named-as-default
import TableRow from '@app/components/elements/table/TableRow/TableRow';
import { useCheckForCaseAccess, handleUserAssignment } from '@app/helpers/cases';

interface PolicyRowProps {
  columns: {
    id?: string;
    label: string;
    sort?: boolean;
    location?: string | string[];
    sortLocation?: string | string[];
    format?: ({ value }: {
      value: {
        effectiveDate: string;
        expirationDate: string;
      }
    }) => React.JSX.Element;
    numericSort?: boolean;
    sortType?: string;
    class?: string;
  }[];
  item?: {
    id: string;
    operator: string;
    assignedOperator: {
      name: string;
      id: string;
    };
    primaryContactId: string;
    metadata: {
      '8ae3a30a-0011-4b2d-abf4-d248b6d47b89': string;
      '5935bd29-ad61-41b9-bfa3-1fbe8f99c88c': string;
    };
    contractTerm: {
      effectiveDate: string;
      expirationDate: string;
    };
  };
  actions: {
    id: string;
    label: string;
    leftIcon: React.FC<React.SVGAttributes<SVGElement>>;
    action?: (id: string) => void;
    isDisabled?: boolean;
    onSelect?: (e: unknown) => void;
    confirmation?: boolean;
  }[];
}

const PolicyRow: React.FC<PolicyRowProps> = ({
  columns,
  item = { metadata: {}, contractTerm: {}, id: '' },
  actions,
}) => {
  const assignedOperator = _.get(item, 'operator');
  const hasAccessToCase = useCheckForCaseAccess(item);
  const dispatch = useDispatch();
  const { operators } = useSelector((state) => ({
    operators: _.get(state, 'operators.listById', {}),
  }), shallowEqual);
  const assignUser = handleUserAssignment(item, jobPutRequest, dispatch);
  const modifiedItem = { ...item };
  let contractTerm;
  if (!_.get(modifiedItem, 'contractTerm', false)) {
    contractTerm = {
      effectiveDate: _.get(modifiedItem.metadata, '8ae3a30a-0011-4b2d-abf4-d248b6d47b89', ''),
      expirationDate: _.get(modifiedItem.metadata, '5935bd29-ad61-41b9-bfa3-1fbe8f99c88c', ''),
    };
    modifiedItem.contractTerm = contractTerm;
  }

  return (
    <TableRow
      id="policy"
      status="open"
      itemId={modifiedItem && modifiedItem.id}
      actions={actions}
      columns={columns.slice(0, columns.length - 2)}
      item={modifiedItem}
      hasAccessToCase={hasAccessToCase}
      useNewActionMenu
    >
      <TableCell id="assigned">
        {hasAccessToCase
          ? (
            <AssignUser
              id="policies"
              defaultLabel="Unassigned"
              rowId={`assign-${modifiedItem.id}`}
              selectedId={_.get(modifiedItem, 'assignedOperator.id') || _.get(modifiedItem, 'primaryContactId') || 'unassigned'}
              handleSelect={(e: unknown) => assignUser(e)}
              options={_.unionBy(
                Object.values(operators),
                [{ id: _.get(modifiedItem, 'assignedOperator.id'), label: _.get(modifiedItem, 'assignedOperator.name') }],
                'id',
              )}
            />
          )
          : <span>{assignedOperator}</span>}
      </TableCell>
    </TableRow>
  );
};

export default PolicyRow;
