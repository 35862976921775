import {
  Button, ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanel, Tab,
  TabPanels,
  Heading,
  chakra,
  Flex,
  Spacer,
  Tooltip,
  Tag,
  TagLabel,
  TagRightIcon,
} from '@chakra-ui/react';
import { InfoCircleIcon } from '@himarley/unity';
import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { createCaseWithUser } from '@app/actions/common';
import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import { DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { cleanPhone } from '@app/helpers/format';
import {
  CaseVisibilityEnum,
  CaseTypeEnum,
  CaseTypeMetadataEnum,
  ContactCaseRoleEnum,
  IFormInput,
} from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import ClaimForm from './claim-form';
import { TAB_FIELDS, META_DATA_MAPPING } from './constants';
import ContactDetailsForm from './contact-details-form';
import GeneralForm from './general-form';
import PolicyForm from './policy-form';

const StyledTab = chakra(Tab, {
  baseStyle: {
    bg: 'gray.50',
    borderColor: 'gray.200',
    borderWidth: '1px',
    _selected: {
      bg: 'white',
      color: 'black',
      borderColor: 'navy.500',
    },
  },
});

const CreateCaseModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isPolicy = location.pathname.includes('policies');
  const [activeTab, setActiveTab] = useState(isPolicy ? 1 : 0);
  const history = useHistory();
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
  const linesOfBusiness = useSelector(
    (state: StateType) => state.organizations?.linesOfBusiness ?? [],
  );
  const userBrand = useSelector((state: StateType) => state.profile.properties.brandId);
  const brands = useSelector((state: StateType) => state.organizations?.branding ?? []);
  const currentUserId = useSelector((state: StateType) => state.auth?.user?._id);

  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);

  const dispatch = useDispatch();
  const methods = useForm<IFormInput>({
    defaultValues: {
      contactLanguage: 'en',
      caseType: isPolicy ? CaseTypeEnum.policy : CaseTypeEnum.claim,
      caseVisibility: CaseVisibilityEnum.public,
      insuranceBrand: brands.find((brand) => brand === userBrand),
      assignedTo: currentUserId,
      contactCaseRole: 'insured' as keyof typeof ContactCaseRoleEnum,
    },
    mode: 'onBlur',
  });

  const buildMetaData = useCallback(<T extends CaseTypeEnum>(data: IFormInput, caseType: T) => {
    const metaData: Record<string, string> = {};
    const caseTypeMapping = META_DATA_MAPPING[caseType] as Partial<
    Record<keyof IFormInput, string>
    >;

    if (caseTypeMapping) {
      Object.entries(caseTypeMapping).forEach(([key, metadataKey]) => {
        const value = data[key as keyof IFormInput];
        if (metadataKey && value) {
          metaData[metadataKey] = value;
        }
      });
    }

    return metaData;
  }, []);

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
    unregister,
    reset,
  } = methods;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    pathSegments.pop();

    const basePath = `/${pathSegments.join('/')}`;

    history.push(basePath);
    reset();
  }, [location.pathname, history, reset]);

  useEffect(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onSubmit = useCallback<SubmitHandler<IFormInput>>((data) => {
    const metaData = buildMetaData(data, data.caseType);

    dispatch(createCaseWithUser({
      type: 'CASE',
      userData: {
        id: data.id,
        phoneNumber: cleanPhone(data.phoneNumber),
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        contactCaseRole: data.contactCaseRole,
        languagePreference: data.contactLanguage,
        zipCode: data.contactZipCode,
      },
      data: {
        caseTypeId: CaseTypeMetadataEnum[data.caseType],
        metaData,
        operatorId: data.assignedTo,
        privacy: data.caseVisibility,
        lineOfBusiness: linesOfBusiness.find((lob) => lob._id === data.lineOfBusiness),
        roleType: data.contactCaseRole,
      },
      userIdName: 'customerId',
      overrideType: data.caseType,
    }));
    handleClose();
  }, [dispatch, linesOfBusiness, handleClose, buildMetaData]);

  const handleTabsChange = useCallback((index: number) => {
    const caseTabArray = [
      CaseTypeEnum.claim,
      CaseTypeEnum.policy,
      CaseTypeEnum.general,
    ];

    setValue('caseType', caseTabArray[index]);

    // Policy number is not required for claim and general tabs
    if (index === 0 || index === 2) {
      unregister('policyNumber');
    }

    const allFields = Object.values(TAB_FIELDS).flat();
    allFields.forEach((field) => {
      if (!TAB_FIELDS[caseTabArray[index]].includes(field)) {
        unregister(field);
      }
    });

    setActiveTab(index);
  }, [setValue, unregister]);

  const isValidPhoneNumber = cleanPhone(getValues('phoneNumber')).length === 10;

  const { scrollbarStyles } = useCustomStyles();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={{ base: 'full', md: 'xl' }}
      scrollBehavior="inside"
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader borderBottom="1px solid" borderColor="gray.200">Create Case</ModalHeader>
          <ModalCloseButton />
          <ModalBody sx={{ ...scrollbarStyles, overflowX: 'hidden' }}>
            <Grid width="100%" templateColumns={{ base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
              <GridItem colSpan={2}>
                <Heading fontWeight="medium" size="sm">Contact Details</Heading>
              </GridItem>
              <ContactDetailsForm setIsPhoneNumberEntered={setIsPhoneNumberEntered} />
              {isPhoneNumberEntered && (
              <>
                <GridItem colSpan={2}>
                  <Heading fontWeight="medium" size="sm">Case Details</Heading>
                </GridItem>
                <GridItem colSpan={2}>
                  <Tabs variant="unstyled" index={activeTab} isFitted onChange={handleTabsChange}>
                    <TabList
                      as={ButtonGroup}
                      isAttached
                      border={0}
                      variant="unstyled"
                      sx={{
                        flexDirection: { base: 'column', md: 'row' },
                        '> button': {
                          borderRadius: '4px',
                          width: { base: '100%', md: 'auto' },
                        },
                      }}
                    >
                      <StyledTab>Claim</StyledTab>
                      <StyledTab>Policy</StyledTab>
                      <StyledTab>General</StyledTab>
                    </TabList>
                    <TabPanels>
                      <TabPanel px={0}>
                        {activeTab === 0 ? <ClaimForm /> : null}
                      </TabPanel>
                      <TabPanel px={0}>
                        {activeTab === 1 ? <PolicyForm /> : null}
                      </TabPanel>
                      <TabPanel px={0}>
                        {activeTab === 2 ? <GeneralForm /> : null}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter borderTop="1px solid" borderColor="gray.200">
            <Flex justifyContent="space-between" width="100%">
              {isDisableMarleyOptIn && isPhoneNumberEntered && (
                <Tag
                  bg="gray.50"
                  py={2}
                  borderRadius="md"
                  px={3}
                >
                  <TagLabel>Did you confirm opt-in?</TagLabel>
                  <Tooltip
                    hasArrow
                    label="By creating this case you confirm that this customer has already consented to receive messages from your organization."
                    placement="top"
                  >
                    <TagRightIcon ml={1} boxSize={4} as={InfoCircleIcon} />
                  </Tooltip>
                </Tag>
              )}
              <Spacer />
              <ButtonGroup spacing={3}>
                <Button
                  variant="ghost"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  data-testid="create-case-submit-button"
                  isLoading={isSubmitting}
                  isDisabled={!isValid || !isValidPhoneNumber}
                >
                  Create Case
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default CreateCaseModal;
