import { defineStyleConfig } from '@chakra-ui/react';

export const tableTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    color: 'gray.900',
    thead: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: '1',
      boxShadow: 'inset 0 1px 0 var(--chakra-colors-gray-200), inset 0 -1px 0 var(--chakra-colors-gray-200)',
      border: '0',
      th: {
        border: '0',
        textTransform: 'none',
        color: 'blue.60',
        fontWeight: 500,
        _hover: {
          boxShadow: 'inset 0 1px 0 var(--chakra-colors-gray-200), inset 0 -1px 0 var(--chakra-colors-gray-200)',
          border: '0',
        },
      },
    },
  },
});

export default tableTheme;
