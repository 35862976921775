import { useToast } from '@chakra-ui/react';
import { CheckmarkFilledIcon, LockIcon } from '@himarley/unity';
import { useQuery } from '@tanstack/react-query';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { updateGroups } from '@app/actions/users';
import { fetchGroups } from '@app/api/groups';
import Menu from '@app/components/chakra/menu';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { Group } from '@app/types/api/group';
import { User } from '@app/types/api/user';

interface AssignGroupProps {
  user: User
}

const AssignGroup: React.FC<AssignGroupProps> = ({
  user,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<string[]>(user.groups ?? []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    setSelectedGroups(user.groups ?? []);
  }, [user.groups]);

  const { data: groups = [], isLoading } = useQuery({
    queryKey: ['groups', debouncedSearch],
    queryFn: () => fetchGroups({
      offset: 0,
      limit: 32,
      searchText: debouncedSearch || undefined,
    }),
    select: (data) => data.groups?.sort(
      (a: Group, b: Group) => sortByAlphabetical(a?.name, b?.name),
    ) || [],
    enabled: true,
    staleTime: 30000,
  });

  const handleMenuClose = useCallback(async () => {
    const addGroups = selectedGroups.filter((groupId) => !user.groups?.includes(groupId)) ?? [];
    const removeGroups = user.groups?.filter((groupId) => !selectedGroups.includes(groupId)) ?? [];

    if (addGroups.length > 0 || removeGroups.length > 0) {
      await dispatch(updateGroups(user, { add: addGroups, remove: removeGroups }, toast));
      setSelectedGroups(user.groups ?? []);
    }
    setSearchQuery('');
  }, [dispatch, selectedGroups, user, toast]);

  const buildButtonLabel = (
    userGroups: string[] = [],
    orgGroups: Group[] = [],
  ) => {
    const actualUserGroups = userGroups.filter(
      (groupId) => orgGroups.some((group) => group._id === groupId),
    );

    const groupsCount = actualUserGroups.length || 0;
    const hasMultipleGroups = groupsCount > 1;

    if (hasMultipleGroups) {
      return `${groupsCount} Groups`;
    }

    if (groupsCount === 1) {
      const groupName = orgGroups.find((group) => group._id === actualUserGroups[0])?.name;
      return groupName || 'No Groups';
    }

    return 'No Groups';
  };

  const buttonLabel = buildButtonLabel(
    [...(user.groups || []), ...(user.groupLeads || [])],
    groups,
  );

  const menuOptions = useMemo(() => {
    const hasGroup = (groupId: string) => selectedGroups.some(
      (group) => group === groupId,
    );

    const hasGroupLead = (groupId: string) => user.groupLeads?.some(
      (group) => group === groupId,
    );

    const getRightIcon = (isLead: boolean | undefined, hasGroupAccess: boolean | undefined) => {
      if (isLead) return LockIcon;
      if (hasGroupAccess) return CheckmarkFilledIcon;
      return undefined;
    };

    const getGroupTitle = (isLead: boolean | undefined, hasGroupAccess: boolean | undefined) => {
      if (isLead) return 'Lead';
      if (hasGroupAccess) return 'Member';
      return 'Member';
    };

    const handleGroupSelect = (groupId: string) => {
      setSelectedGroups((current) => {
        if (hasGroup(groupId)) {
          return current.filter((id) => id !== groupId);
        }
        return [...current, groupId];
      });
    };

    return [...groups]
      .sort((a, b) => {
        const aIsLead = hasGroupLead(a._id || '');
        const bIsLead = hasGroupLead(b._id || '');
        if (aIsLead && !bIsLead) return -1;
        if (!aIsLead && bIsLead) return 1;
        return 0;
      })
      .map((group: Group) => {
        const isLead = hasGroupLead(group._id || '');
        const hasGroupAccess = hasGroup(group._id || '');
        return {
          id: group._id || '',
          label: group.name,
          onClick: isLead ? undefined : () => handleGroupSelect(group._id || ''),
          rightIcon: getRightIcon(isLead, hasGroupAccess),
          rightIconTooltip: isLead ? 'You can change the lead status from the group management page.' : undefined,
          closeOnSelect: false,
          group: getGroupTitle(isLead, hasGroupAccess),
          isDisabled: isLead,
        };
      });
  }, [groups, selectedGroups, user.groupLeads]);

  return (
    <Menu
      id="assign-group"
      defaultLabel={buttonLabel}
      onClose={handleMenuClose}
      options={menuOptions}
      menuButtonProps={{
        w: '120px',
      }}
      searchable
      searchValue={searchQuery}
      onSearchChange={setSearchQuery}
      isLoading={isLoading}
      sx={{
        '.chakra-menu__menuitem': {
          _disabled: {
            opacity: 1,
          },
        },
      }}
    />
  );
};

export default AssignGroup;
