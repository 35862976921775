import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  type ModalContentProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';

interface ModalProps {
  className?: string;
  isModalOpen: boolean;
  toggleModal: () => void;
  title?: string;
  children: React.ReactNode;
  hasBodyFooterInChildren?: boolean;
  footer?: React.ReactNode;
  withOverlay?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  scrollBehavior?: 'inside' | 'outside';
  as?: ModalContentProps['as']
  isCentered?: boolean;
  testId?: string;
}

function useModal(initialState = false) {
  const [isModalOpen, setIsModalOpen] = useState(initialState);

  const toggleModal = (isVisible = !isModalOpen) => {
    setIsModalOpen(isVisible);
  };

  return {
    isModalOpen,
    toggleModal,
  };
}

const Modal: React.FC<ModalProps> = ({
  isModalOpen,
  className,
  toggleModal,
  title,
  children,
  footer,
  hasBodyFooterInChildren,
  withOverlay = true,
  size = 'lg',
  scrollBehavior = 'inside',
  as = 'div',
  isCentered,
  testId = 'chakra',
}) => (
  <ChakraModal
    isOpen={isModalOpen}
    onClose={toggleModal}
    size={size}
    scrollBehavior={scrollBehavior}
    isCentered={isCentered}
  >
    {withOverlay && <ModalOverlay />}
    <ModalContent className={className} as={as} data-testid={`${testId}-modal`}>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      {hasBodyFooterInChildren ? children : (
        <ModalBody>
          {children}
        </ModalBody>
      )}
      {footer && (
      <ModalFooter>
        {footer}
      </ModalFooter>
      )}
    </ModalContent>
  </ChakraModal>
);

export default Modal;
export { useModal };
