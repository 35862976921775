import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

export const menuTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    item: {
      '&[data-multiselected="true"]': {
        bg: 'gray.50 !important',
      },
      _hover: {
        bg: 'gray.50',
      },
      _focus: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'gray.100',
      },
    },
    list: {
      zIndex: 2,
    },
    groupTitle: {
      margin: 3,
    },
  }),
});

export default menuTheme;
