/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import GroupLeadIcon from '../../../icons/GroupLeadIcon';
import AddIcon from '../../../icons/AddIcon';
import CloseButtonIcon from '../../../icons/CloseButtonIcon';
import IconButton from '../../../elements/buttons/IconButton/IconButton';

export const formatRoles = (roles) => (roles.length > 0 ? roles.join(', ') : 'No Roles');

const UserListItem = ({
  item, updateList, isLead, isSelected,
}) => {
  const roles = _.get(item, 'roles', []);
  const role = formatRoles(roles);

  return (
    <div className="item">
      <div className="item-column">
        <div className="name">{_.get(item, 'name', '')}</div>
        <div className="role-row">
          <div className="role">{role.toLowerCase()}</div>
          {isSelected && isLead
            ? <div data-testid="lead-icon" className="lead-icon"><GroupLeadIcon /></div> : <div />}
        </div>
      </div>
      {isSelected ? (
        <IconButton
          className="remove-icon"
          data-testid="remove-button"
          tooltip="Remove member"
          onClick={updateList}
        >
          <CloseButtonIcon className="close-button-icon" />
        </IconButton>
      ) : (
        <div className="button-column">
          <IconButton
            className="lead-icon"
            data-testid="lead-button"
            tooltip="Add as group lead"
            onClick={() => updateList({ isLead: true })}
          >
            <GroupLeadIcon />
          </IconButton>
          <IconButton
            className="add-icon"
            data-testid="add-button"
            tooltip="Add member"
            onClick={updateList}
          >
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

UserListItem.propTypes = {
  updateList: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object),
};

UserListItem.defaultProps = {
  item: {},
};

export default UserListItem;
