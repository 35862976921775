import {
  FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import React from 'react';
import {
  Path, PathValue, useFormContext, type RegisterOptions, type FieldValues,
} from 'react-hook-form';

interface TextFieldProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  type?: string;
  formatValue?: (value?: string) => string;
  fieldInfo?: string;
  leftElement?: string;
  registerOptions?: RegisterOptions<T>,
  hidden?: boolean;
  isDisabled?: boolean;
}

const TextField = <T extends FieldValues>({
  label,
  name,
  type = 'text',
  formatValue,
  fieldInfo,
  leftElement,
  registerOptions,
  hidden,
  isDisabled,
}: TextFieldProps<T>) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<T>();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = event.target.value;
    if (formatValue) {
      formattedValue = formatValue(event.target.value);
    }
    setValue(name, formattedValue as PathValue<T, Path<T>>, { shouldValidate: true });
    await trigger(name);
  };

  const updatedRegisterOptions = registerOptions || {};

  if (formatValue) {
    updatedRegisterOptions.onChange = handleChange;
  }

  const isRequired = updatedRegisterOptions?.required;

  return (
    <FormControl
      id={name}
      isInvalid={!!errors[name]}
      hidden={hidden}
      isDisabled={isDisabled}
    >
      <FormLabel htmlFor={name}>{`${label}${isRequired ? ' (Required)' : ''}`}</FormLabel>
      <InputGroup>
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <Input
          {...register(name, updatedRegisterOptions)}
          type={type}
        />
      </InputGroup>
      {fieldInfo && <FormHelperText color="green.500">{fieldInfo}</FormHelperText>}
      <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
    </FormControl>
  );
};

export default TextField;
