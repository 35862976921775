import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

import TextField from '@app/components/chakra/fields/text-field';
import { type IFormInput } from '@app/types/create-case';

import SharedForm from './shared-form';

const GeneralForm = () => (
  <Grid width="100%" templateColumns={{ sm: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
    <SharedForm />
    <GridItem colSpan={2}>
      <TextField<IFormInput>
        label="Reference"
        name="reference"
      />
    </GridItem>
  </Grid>
);

export default GeneralForm;
