import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuDivider,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  CheckmarkFilledIcon,
  GlobalIcon,
  CaretDownIcon,
} from '@himarley/unity';
import React from 'react';

import useScreenSize from '@app/components/Hooks/use-screen-size';
import { languagesById, languageList } from '@app/helpers/languages';

interface LanguageMenuProps {
  languagePreference: string;
  selectLanguage: (language: string) => void;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ languagePreference, selectLanguage }) => {
  const isSmallScreen = useScreenSize();

  return (
    <Menu placement="top">
      {isSmallScreen ? (
        <MenuButton
          as={IconButton}
          variant="ghost"
          size="sm"
          icon={<Icon as={GlobalIcon} />}
        />
      ) : (
        <MenuButton
          as={Button}
          variant="ghost"
          size="sm"
          leftIcon={<GlobalIcon as={Icon} />}
          rightIcon={<CaretDownIcon as={Icon} />}
        >
          {languagesById[languagePreference] || 'en'}
        </MenuButton>
      )}
      <MenuList maxHeight="400px" overflowY="scroll" defaultValue={languagesById[languagePreference] || 'en'}>
        <MenuItem
          key={languagePreference}
          onClick={() => selectLanguage(languagePreference)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {languagesById[languagePreference]}
          <Icon as={CheckmarkFilledIcon} mr={2} />
        </MenuItem>
        <MenuDivider />

        {languageList
          .filter((language) => language.id !== languagePreference)
          .map((language) => (
            <MenuItem
              key={language.id}
              onClick={() => selectLanguage(language.id)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {language.label}
              {languagePreference === language.id && <Icon as={CheckmarkFilledIcon} mr={2} />}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageMenu;
