import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    marley: {
      root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        gap: 10,
        marginX: 8,
        marginTop: 5,
        bg: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        marginBottom: 0,
      },
      tab: {
        fontSize: '.875rem',
        height: '3.8125rem',
        color: 'gray.500',
        padding: 1,
        fontWeight: 'normal',
        transition: 'color 0.2s',
        _hover: {
          color: 'gray.900',
        },
        _selected: {
          color: 'gray.900',
          fontWeight: 500,
          borderBottom: 'none',
          boxShadow: 'inset 0 -4px 0 var(--chakra-colors-marleyRed-500)',
        },
      },
      tabpanel: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        inset: 0,
        overflow: 'auto',
        paddingX: 8,
        paddingTop: 12,
        paddingBottom: 0,
      },
      tabpanels: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        flex: 1,
        minHeight: 0,
      },
    },
  },
  defaultProps: {
    variant: 'marley',
  },
});

export default tabsTheme;
