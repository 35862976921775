import { Box } from '@chakra-ui/react';
import {
  BriefcaseIcon, UserIcon, UserGroupIcon, SearchBar, UserPlaceholderIcon, CheckmarkFilledIcon,
} from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import Menu from '@app/components/chakra/menu';
import UserRow from '@app/components/chakra/user-row';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { groupType, operatorType } from '@app/models/marleyTypes';
import { type User } from '@app/types/api/user';
import { type StateType } from '@app/types/reducer-state';

interface Properties {
  operators?: User[];
  groups?: User[];
}

interface AssignedFilterProps {
  setProperties: (properties: Properties) => void;
  properties: Properties;
}

const AssignedFilter: React.FC<AssignedFilterProps> = ({ setProperties, properties }) => {
  const dispatch = useDispatch();

  const [userSearchFilter, setUserSearchFilter] = useState('');
  const [groupSearchFilter, setGroupSearchFilter] = useState('');
  const [operatorProperties, setOperatorProperties] = useState<User[]>(properties?.operators || []);
  const [groupProperties, setGroupProperties] = useState<User[]>(properties?.groups || []);

  useEffect(() => {
    setOperatorProperties(properties?.operators || []);
    setGroupProperties(properties?.groups || []);
  }, [properties]);

  const {
    auth,
    users,
    groups,
  } = useSelector((state: StateType) => ({
    auth: {
      ...state.auth,
      user: {
        ...state.auth.user,
        name: `${state.auth.user.firstName} ${state.auth.user.lastName} (me)`,
        id: state.auth.user._id,
      },
    },
    users: (state.operators?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
    groups: (state.groups?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
  }));

  const loadUsers = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(operatorType, {
      ...params, offset: 0, limit: 20, active: true,
    }, 'users')),
    [dispatch],
  );

  const debouncedHandleSearchUsers = useMemo(
    () => debounce((searchFilter) => {
      const userQuery: {
        offset?: number;
        limit?: number;
        searchText?: string;
      } = {
        offset: 0,
        limit: 20,
        searchText: searchFilter || '',
      };

      loadUsers(userQuery);
    }, 500),
    [], // Dependencies here are empty because debounce persists across renders
  );

  const handleSearchUsers = useCallback(() => {
    debouncedHandleSearchUsers(userSearchFilter);
  }, [debouncedHandleSearchUsers, userSearchFilter]);

  useEffect(() => {
    handleSearchUsers();

    return () => {
      debouncedHandleSearchUsers.cancel();
    };
  }, [userSearchFilter, handleSearchUsers, debouncedHandleSearchUsers]);

  const loadGroups = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(groupType, { ...params, offset: 0, limit: 20 }, 'groups')),
    [dispatch],
  );

  const debouncedHandleSearchGroups = useMemo(
    () => debounce((searchFilter) => {
      const groupQuery: {
        offset?: number;
        limit?: number;
        searchText?: string;
      } = {
        searchText: searchFilter || '',
      };

      loadGroups(groupQuery);
    }, 500),
    [], // Dependencies here are empty because debounce persists across renders
  );

  const handleSearchGroups = useCallback(() => {
    debouncedHandleSearchGroups(groupSearchFilter);
  }, [debouncedHandleSearchGroups, groupSearchFilter]);

  useEffect(() => {
    handleSearchGroups();

    return () => {
      debouncedHandleSearchGroups.cancel();
    };
  }, [groupSearchFilter, handleSearchGroups, debouncedHandleSearchGroups]);

  const updateProperties = ({ user, group }: {
    user?: User,
    group?: User
  }) => {
    let newOperatorProperties = [...operatorProperties];
    let newGroupProperties = [...groupProperties];

    if (user && user.id === 'all') {
      newOperatorProperties = [];
      newGroupProperties = [];
    } else {
      if (
        user
        && operatorProperties.some((o) => o.id === user.id)
      ) {
        newOperatorProperties = newOperatorProperties?.filter(
          (operator) => operator.id !== user.id,
        );
      } else if (user) {
        newOperatorProperties = newOperatorProperties
          ? [...newOperatorProperties, user] : [user];
      }

      if (
        group
        && groupProperties.some((g) => g.id === group.id)
      ) {
        newGroupProperties = newGroupProperties.filter((g) => g.id !== group.id);
      } else if (group) {
        newGroupProperties = newGroupProperties
          ? [...newGroupProperties, group] : [group];
      }
    }

    setOperatorProperties(newOperatorProperties);
    setGroupProperties(newGroupProperties);
  };

  const selectedUsersDict = useMemo(() => operatorProperties.reduce(
    (acc: { [key: string]: User }, user) => {
      const userId = user.id || user._id;
      if (userId) {
        acc[userId] = user;
      }
      return acc;
    },
    {},
  ), [operatorProperties]);

  const selectedGroupsDict = useMemo(() => groupProperties.reduce(
    (acc: { [key: string]: User }, group) => {
      const groupId = group.id || group._id;
      if (groupId) {
        acc[groupId] = group;
      }
      return acc;
    },
    {},
  ), [groupProperties]);

  const options = [
    {
      id: 'allCases',
      label: 'All Cases',
      leftIcon: BriefcaseIcon,
      closeOnSelect: false,
      onClick: () => updateProperties({
        user: {
          id: 'all',
          name: 'All ',
        },
      }),
      rightIcon: !operatorProperties.length && !groupProperties.length ? CheckmarkFilledIcon : undefined,
    },
    {
      id: 'unassigned',
      label: 'Unassigned',
      leftIcon: UserPlaceholderIcon,
      closeOnSelect: false,
      onClick: () => updateProperties({
        user: {
          id: 'unassigned',
          name: 'Unassigned',
        },
      }),
      rightIcon: selectedUsersDict?.unassigned ? CheckmarkFilledIcon : undefined,
    },
    {
      id: 'chooseUsers',
      label: 'Choose Users',
      leftIcon: UserIcon,
      badgeCount: operatorProperties.filter((o) => o.id !== 'unassigned').length,
      searchComponent: (
        <Box pb={1} px={3} w="400px" sx={{ div: { width: 'unset' } }}>
          <SearchBar
            id="users"
            placeholder="Search Users"
            value={userSearchFilter}
            onValueChange={setUserSearchFilter}
            onClear={() => setUserSearchFilter('')}
          />
        </Box>
      ),
      childrenItems: [
        {
          id: auth.user._id,
          label: (
            <UserRow
              option={auth.user}
              size="sm"
            />
          ),
          onClick: () => updateProperties({ user: auth.user }),
          rightIcon: selectedUsersDict?.[auth.user._id || ''] ? CheckmarkFilledIcon : undefined,
        },
        ...users.filter((user) => (user.id !== auth.user._id)).map((user) => ({
          id: user.id,
          label: <UserRow option={user} size="sm" />,
          onClick: () => updateProperties({ user }),
          rightIcon: selectedUsersDict?.[user.id || ''] ? CheckmarkFilledIcon : undefined,
        })),
      ],
    },
    {
      id: 'chooseGroups',
      label: 'Choose Groups',
      leftIcon: UserGroupIcon,
      badgeCount: groupProperties.length,
      searchComponent: (
        <Box pb={1} px={3} w="400px" sx={{ div: { width: 'unset' } }}>
          <SearchBar
            id="groups"
            placeholder="Search Groups"
            value={groupSearchFilter}
            onValueChange={setGroupSearchFilter}
            onClear={() => setGroupSearchFilter('')}
          />
        </Box>
      ),
      childrenItems: groups.map((group) => ({
        id: group.id,
        label: <UserRow option={group} size="sm" />,
        onClick: () => updateProperties({ group }),
        rightIcon: selectedGroupsDict?.[group.id || ''] ? CheckmarkFilledIcon : undefined,
      })),
    },
  ];

  const defaultLabel = useMemo(() => {
    let label = 'Assigned: All';

    if (properties) {
      const pOperators = properties.operators || [];
      const pGroups = properties.groups || [];
      const hasUnassigned = pOperators.some((o) => o.id === 'unassigned');

      if (pOperators.length === 1
    && pGroups.length === 0
      ) {
        label = `Assigned: ${pOperators[0].name}`;
      } else if (pGroups.length === 1
    && pOperators.length === 0
      ) {
        label = `Assigned: ${pGroups[0].name}`;
      } else if (pOperators.length === 2
    && hasUnassigned
    && pGroups.length === 0
      ) {
        label = `Assigned: ${pOperators[0].name}, ${pOperators[1].name}`;
      } else if (pOperators.length) {
        label = `Assigned: ${pOperators.length} User${pOperators.length > 1 ? 's' : ''}`;
        if (pGroups.length) {
          label += `, ${pGroups.length} Group${pGroups.length > 1 ? 's' : ''}`;
        }
      } else if (pGroups.length) {
        label = `Assigned: ${pGroups.length} Group${pGroups.length > 1 ? 's' : ''}`;
      }
    }

    return label;
  }, [properties]);

  return (
    <Menu
      sx={{
        button: {
          marginLeft: '0',
        },
        minWidth: '220px',
        position: 'unset',
      }}
      id="assigned-filter"
      defaultLabel={defaultLabel}
      options={options}
      menuButtonProps={{ variant: 'ghost' }}
      onClose={
        () => setProperties({ operators: operatorProperties, groups: groupProperties })
      }
    />
  );
};

export default AssignedFilter;
