/* eslint-disable no-nested-ternary */
import {
  Tr, Td, IconButton, Flex, Text, Icon,
} from '@chakra-ui/react';
import { CopyIcon, MoreIcon } from '@himarley/unity';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleConfirmation } from '@app/actions/ui';
import AssignGroup from '@app/components/chakra/action-menus/assign-group';
import AssignRole from '@app/components/chakra/action-menus/assign-role';
import Menu from '@app/components/chakra/menu';
import UserRow from '@app/components/chakra/user-row';
import { formatPhoneNumber } from '@app/helpers/format';
import { type User } from '@app/types/api/user';

interface OperatorRowProps {
  user: User;
  itemId: string;
  actions: {
    id: string;
    label: React.ReactNode;
    confirmation?: boolean;
    action?: (id: string) => void;
    openModal?: () => void;
  }[];
  hasAccessToCase?: boolean;
  authId: string;
  rowId?: string;
}

const OperatorRow: React.FC<OperatorRowProps> = ({
  user,
  itemId,
  actions,
  hasAccessToCase = true,
  authId,
  rowId = 'operator-row',
}) => {
  const dispatch = useDispatch();

  const memoizedSelect = useCallback(
    (selectId: string) => {
      const action = actions.find((a) => a.id === selectId);
      if (action?.openModal) {
        action.openModal();
      }
      if (action?.confirmation) {
        dispatch(
          toggleConfirmation({
            id: action.id,
            isOpen: true,
            selectedId: user.id,
          }),
        );
      } else if (action?.action) {
        action.action(itemId);
      }
    },
    [actions, dispatch, user.id, itemId],
  );

  const archivedTdStyle = {
    opacity: '50%',
  };

  return (
    <Tr className={`${!hasAccessToCase ? 'redacted' : ''}`} data-testid={rowId}>
      <Td data-testid="operator-name" sx={user?.archived ? archivedTdStyle : {}} noOfLines={1} maxWidth={250}>
        <UserRow
          showTitle
          option={user}
          size="sm"
          isYou={authId === user?.id}
          boldName
          borderRadius="full"
          showEmail={false}
          userNameFontWeight="500"
        />
      </Td>
      <Td
        data-testid="operator-oktaUserId"
        sx={user?.archived ? { ...archivedTdStyle, ...{ '&:hover': { button: { visibility: 'visible' } } } } : { '&:hover': { button: { visibility: 'visible' } } }}
      >
        {user?.oktaUserId && (
          <Flex gap={4} alignItems="center">
            <Text noOfLines={1} maxWidth={200} title={user?.oktaUserId}>
              {user?.oktaUserId}
            </Text>
            <IconButton
              visibility="hidden"
              icon={<Icon as={CopyIcon} />}
              variant="ghost"
              onClick={() => user?.oktaUserId && navigator.clipboard.writeText(user.oktaUserId)}
              title="Copy unique id"
              aria-label="unique id"
              size="sm"
            />
          </Flex>
        )}
      </Td>
      <Td
        data-testid="operator-email"
        sx={user?.archived ? { ...archivedTdStyle, ...{ '&:hover': { button: { visibility: 'visible' } } } } : { '&:hover': { button: { visibility: 'visible' } } }}
      >
        {user?.email && (
          <Flex gap={4} alignItems="center">
            <Text noOfLines={1} maxWidth={200} title={user?.email}>
              {user?.email}
            </Text>
            <IconButton
              visibility="hidden"
              icon={<Icon as={CopyIcon} />}
              variant="ghost"
              onClick={() => user?.email && navigator.clipboard.writeText(user.email)}
              title="Copy email"
              aria-label="email"
              size="sm"
            />
          </Flex>
        )}
      </Td>
      <Td id="phone" data-testid="operator-phone" sx={user?.archived ? archivedTdStyle : {}}>
        {user?.displayPhoneNumber || user?.contactNumber
          ? formatPhoneNumber(user?.displayPhoneNumber || user?.contactNumber)
          : ''}
      </Td>
      <Td id="last-active">{user?.lastLoginTime}</Td>
      <Td id="roles" sx={user?.archived ? { ...archivedTdStyle, pointerEvents: 'none' } : {}}>
        <AssignRole user={{ ...user, roles: user.roles || [] }} />
      </Td>
      <Td id="groups" sx={user?.archived ? { ...archivedTdStyle, pointerEvents: 'none' } : {}}>
        <AssignGroup user={{
          ...user,
          groups: user.groups || [],
          groupLeads: user.groupLeads || [],
        }}
        />
      </Td>
      {actions?.length > 0 && (
        <Td id="actions">
          {hasAccessToCase && (
            <Menu
              id="actions"
              defaultLabel="Actions"
              handleSelect={memoizedSelect}
              options={actions.map((action) => ({
                ...action,
                label: action.label || 'Action',
              }))}
              selectedId=""
              menuButtonProps={{
                icon: MoreIcon,
                as: IconButton,
                variant: 'ghost',
              }}
            />
          )}
        </Td>
      )}
    </Tr>
  );
};

export default OperatorRow;
