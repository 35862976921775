/* eslint-disable import/prefer-default-export */
export const FNOL_HOME = 'FNOL:HOME';
export const OHIO_MUTUAL_AUTO = 'OHIO_MUTUAL:FNOL:AUTO';
export const FNOL_AUTO = 'FNOL:AUTO';
export const FNOL_CAPTURE_LOSS_DATA = 'FNOL:CAPTURE_LOSS_DATA';

export const WORKFLOW_LEGACY_CREATION_SOURCE = 'SYSTEM:WORKFLOW';
export const WORKFLOW_FNOL_CREATION_SOURCE = 'SYSTEM:WORKFLOW:FNOL';

export const WORKFLOW_FNOL_CREATED_BY = 'Conversational FNOL';

export const FNOL_WORKFLOW_CREATION_SOURCES = [
  WORKFLOW_FNOL_CREATION_SOURCE,
  WORKFLOW_LEGACY_CREATION_SOURCE,
];
