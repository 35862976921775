import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getLookerUrl as getLookerUrlAction } from '@app/actions/analytics';
import withLoading from '@app/components/HigherOrderComponents/withLoading';
import { operatorType } from '@app/models/marleyTypes';

import './LookerDashboard.less';

import selector from './selector';

interface LookerDashboardProps {
  dashboardName: string;
  getLookerUrl: (dashboardName: string, timezone: string) => void;
  lookerUrl: string;
}

const LookerDashboard: React.FC<LookerDashboardProps> = ({
  dashboardName,
  getLookerUrl,
  lookerUrl,
}) => {
  useEffect(() => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = timezone.replace(/\//ig, '-');
    getLookerUrl(dashboardName, timezone);
  }, [dashboardName, getLookerUrl]);

  return (
    <div className="analytics-dashboard">
      <iframe
        src={lookerUrl}
        title="looker-dashboard"
        style={{
          border: '0',
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      />
    </div>
  );
};

const LookerDashboardWithLoading = withLoading(LookerDashboard, { type: operatorType });

export default connect((state) => selector(state), {
  getLookerUrl: getLookerUrlAction,
  operatorType,
})(LookerDashboardWithLoading);
