/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Menu,
  MenuButton,
  MenuList,
  Box,
  VStack,
  Text,
  MenuItem,
  Button,
  Flex,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
import {
  CheckmarkFilledIcon, CaretUpIcon, CaretDownIcon, SearchBar,
} from '@himarley/unity';
import React, { useState, useRef } from 'react';

interface SearchableDropdownProps {
  items?: Array<{ id: string; label?: string, avatar?: React.ReactNode }>;
  selectedItemsMap: Map<string, string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  handleSearch: (searchText: string) => void;
  disabled?: boolean;
  defaultLabel: string;
  entityLabel: string;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  selectedItemsMap,
  setSelectedItems,
  handleSearch,
  disabled,
  defaultLabel,
  entityLabel,
}) => {
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const handleSearchChange = (text: string) => {
    setSearchText(text);
    handleSearch(text);
  };

  const handleSelectItem = (item: { id: string; label?: string }) => {
    const newSelectedItems = new Map(selectedItemsMap);
    newSelectedItems.has(item.id)
      ? newSelectedItems.delete(item.id)
      : newSelectedItems.set(item.id, item.label ?? '');
    setSelectedItems(newSelectedItems);
  };

  const getDisplayLabel = () => {
    const selectedCount = selectedItemsMap.size;

    if (selectedCount === 0) {
      return defaultLabel;
    } if (selectedCount === 1) {
      return Array.from(selectedItemsMap.values())[0];
    }
    return `${selectedCount} ${entityLabel}`;
  };

  const handleClearSelection = () => {
    setSelectedItems(new Map());
  };

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <Flex width="100%">
        <MenuButton
          data-testid="searchable-dropdown-button"
          as={Button}
          variant="outline"
          flex="1"
          isDisabled={disabled}
          maxWidth="140px"
          maxHeight="32px"
          rightIcon={(
            <Icon
              as={isOpen ? CaretUpIcon : CaretDownIcon}
              color="gray.500"
            />
          )}
        >
          <Text
            as="span"
            noOfLines={1}
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
            style={{
              display: 'block',
            }}
          >
            {getDisplayLabel()}
          </Text>
        </MenuButton>
      </Flex>
      <MenuList width="340px" padding={0}>
        <Box p={2}>
          <Flex
            align="center"
            justify="space-between"
          >
            <Box w="198px" sx={{ div: { width: 'unset' } }}>
              <SearchBar
                id="group-filter-search"
                placeholder="Search groups..."
                value={searchText}
                onValueChange={handleSearchChange}
                onClear={() => handleSearchChange('')}
                ref={searchInputRef}
              />
            </Box>
            { selectedItemsMap?.size > 0
              ? (
                <Button
                  onClick={handleClearSelection}
                  disabled={disabled}
                  variant="plain"
                  height="auto"
                  padding="0"
                  ml="12px"
                  mr="8px"
                >
                  <Text
                    color="blue.60"
                    fontSize="0.9rem"
                    fontWeight="400"
                    width="100%"
                    minWidth="88px"
                    whiteSpace="nowrap"
                    textAlign="right"
                    alignSelf="center"
                    justifyContent="center"
                    margin="0"
                  >
                    Clear Selected
                  </Text>
                </Button>
              )
              : (
                <Text
                  ml="12px"
                  mr="8px"
                  mb="0"
                  color="blue.60"
                  fontSize="0.9rem"
                  fontWeight="400"
                  width="auto"
                  minWidth="88px"
                  whiteSpace="nowrap"
                  textAlign="right"
                  alignSelf="right"
                  justifyContent="center"
                >
                  {`${selectedItemsMap.size} Selected`}
                </Text>
              )}

          </Flex>
        </Box>
        <Box
          w="100%"
          borderTop="1px solid"
          borderColor="gray.200"
          paddingBottom="2px"
        />
        <Box
          maxH="50vh"
          overflowY="auto"
        >
          <VStack
            align="start"
            spacing={0}
            paddingBottom="4px"
          >
            {items?.map((item) => (
              <MenuItem
                data-testid={`searchable-dropdown-item-${item?.id}`}
                height="40px"
                key={item.id}
                onClick={() => handleSelectItem(item)}
                icon={React.isValidElement(item.avatar) ? item.avatar : undefined}
                backgroundColor={selectedItemsMap.has(item.id) ? 'gray.50' : 'white'}
              >
                <Flex justify="space-between" align="center" width="100%" gap={3}>
                  {item.label}
                  {selectedItemsMap.has(item.id) && <Icon as={CheckmarkFilledIcon} />}
                </Flex>
              </MenuItem>
            ))}
          </VStack>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SearchableDropdown;
