/* eslint-disable import/prefer-default-export */
import { type User } from '@app/types/api/user';

export const populateMissingNameLabels = ({ selected, entityMap }: {
  selected: Map<string, string>;
  entityMap: { [key: string]: User; };
}) => {
  const selectedIterator = selected.keys();
  const populatedEntityLabels = new Map();
  let id = selectedIterator.next().value;
  while (typeof id !== 'undefined') {
    const value = selected.get(id);
    if (value) {
      populatedEntityLabels.set(id, value);
    } else {
      const info = entityMap?.[id];
      if (info?.name) {
        populatedEntityLabels.set(id, info.name);
      }
    }
    id = selectedIterator.next().value;
  }
  return populatedEntityLabels;
};
