import {
  Card, CardBody, Text, Flex, Spacer, VStack, Tooltip,
  Tag, TagLabel, TagLeftIcon,
} from '@chakra-ui/react';
import { CheckCircleIcon, InfoCircleIcon } from '@himarley/unity';
import React from 'react';

import { DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { formatPhoneNumberV2 } from '@app/helpers/format';
import { type Contact } from '@app/types/create-case';

interface ContactCardProps {
  contact: Contact;
}
const OPTED_IN = 'OPTED_IN';

const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const optedIn = contact?.optIn?.status === OPTED_IN;

  let optInWidget = null;

  if (!isDisableMarleyOptIn) {
    if (optedIn) {
      optInWidget = (
        <Tag variant="outline" colorScheme="green">
          <TagLeftIcon boxSize={4} data-testid="opted-in-icon" color="green.500" as={CheckCircleIcon} />
          <TagLabel>Opted-In</TagLabel>
        </Tag>
      );
    } else {
      optInWidget = (
        <Tooltip
          hasArrow
          label="Customers that have not previously opted-in to receive texts must opt-in before you can message them on this case."
          placement="top"
        >
          <Tag variant="outline" colorScheme="gray">
            <TagLeftIcon boxSize={4} color="gray.500" as={InfoCircleIcon} />
            <TagLabel color="gray.500">Not Opted-In</TagLabel>
          </Tag>
        </Tooltip>
      );
    }
  }

  return (
    <Card variant="outline" data-testid="contact-card">
      <CardBody p={3}>
        <Flex minWidth="max-content" alignItems="center">
          <VStack alignItems="baseline" gap={1}>
            <Text fontSize="md">
              {contact?.profile?.firstName || contact?.firstName}
              {' '}
              {contact?.profile?.lastName || contact?.lastName}
            </Text>
            <Text color="gray.500" fontSize="sm">{formatPhoneNumberV2(contact?.profile?.phoneNumber || contact?.phoneNumber)}</Text>
          </VStack>
          <Spacer />
          <Text>{optInWidget}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ContactCard;
