import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

interface AlertDialogProps {
  title: string;
  isDialogOpen: boolean;
  toggleDialog: () => void;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void;
  confirmColor?: string;
  id?: string;
  isDisabled?: boolean;
}

function useAlertDialog(initialState = false) {
  const [isDialogOpen, setIsDialogOpen] = useState(initialState);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return {
    isDialogOpen,
    toggleDialog,
  };
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  isDialogOpen,
  toggleDialog,
  children,
  cancelText,
  confirmText,
  onConfirm,
  confirmColor = 'navy',
  id = 'alert-dialog',
  isDisabled = false,
}) => {
  const cancelRef = useRef(null);

  return (
    <ChakraAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={toggleDialog}
      isOpen={isDialogOpen}
      isCentered
      data-testId={id}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {children}
        </AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            {cancelText && (
            <Button
              data-testid={`${id}-cancel-button`}
              variant="outline"
              ref={cancelRef}
              onClick={toggleDialog}
            >
              {cancelText}
            </Button>
            )}
            {confirmText && (
            <Button
              data-testid={`${id}-confirm-button`}
              onClick={onConfirm}
              colorScheme={confirmColor}
              isDisabled={isDisabled}
            >
              {confirmText}
            </Button>
            )}
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
export { useAlertDialog };
