import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import TextField from '@app/components/chakra/fields/text-field';
import { CHECK_USER } from '@app/constants/endpoints';
import { PHONE_TYPES } from '@app/constants/phoneTypes';
import { cleanPhone, formatPhoneNumberV2 } from '@app/helpers/format';
import { fetchData } from '@app/hooks/react-query-helpers';
import { type IFormInput } from '@app/types/create-case';

const PhoneNumberTextField = () => {
  const queryClient = useQueryClient();
  const [hasValidated, setHasValidated] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean | string>(false);
  const { getFieldState, watch } = useFormContext();
  const fieldState = getFieldState('phoneNumber');
  const phoneNumber = watch('phoneNumber');

  // Reset validation when phone number changes
  useEffect(() => {
    setHasValidated(false);
    setIsValid(false);
  }, [phoneNumber]);

  const validatePhoneNumber = useCallback(
    async (value: string): Promise<boolean | string> => {
      const cleanedPhoneNumber = cleanPhone(value);
      if (cleanedPhoneNumber.length === 10 && !hasValidated) {
        try {
          const data = await queryClient.fetchQuery({
            queryKey: ['validatePhoneNumber', cleanedPhoneNumber],
            queryFn: () => fetchData(CHECK_USER(cleanedPhoneNumber)),
          });

          const isValidPhone = data?.type === PHONE_TYPES.MOBILE || data?.type === PHONE_TYPES.VOIP;
          const validValue = isValidPhone || 'Invalid phone number';
          setIsValid(validValue);
          setHasValidated(true);
          return validValue;
        } catch (error) {
          return 'Error validating phone number';
        }
      }
      return isValid;
    },
    [hasValidated, isValid, queryClient],
  );

  return (
    <TextField<IFormInput>
      label="Mobile Number"
      name="phoneNumber"
      formatValue={formatPhoneNumberV2}
      fieldInfo={!fieldState.invalid && hasValidated ? 'SMS Supported' : undefined}
      leftElement="+1"
      registerOptions={{
        required: 'Mobile phone number is required',
        validate: validatePhoneNumber,
        minLength: { value: 14, message: 'Phone number must be 10 digits' },
        maxLength: { value: 14, message: 'Phone number must be 10 digits' },
      }}
    />
  );
};

export default PhoneNumberTextField;
