import { useToast } from '@chakra-ui/react';
import { CheckmarkFilledIcon } from '@himarley/unity';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addUserRoles, removeUserRoles } from '@app/actions/users';
import Menu from '@app/components/chakra/menu';
import { SYSTEM_ADMIN } from '@app/constants/permissions';
import { SYS_ADMIN } from '@app/constants/roles';
import { useCheckPermissions } from '@app/helpers/common';
import { User } from '@app/types/api/user';

interface AssignRoleProps {
  user: User;
}

const AssignRole: React.FC<AssignRoleProps> = ({
  user,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [selectedRoles, setSelectedRoles] = useState<string[]>(user.roles ?? []);

  const hasRole = (roleName: string) => selectedRoles.some((role) => role === roleName);

  const orgRoles = useSelector((state) => state.users.groups);

  const handleRoleSelect = (roleName: string) => {
    setSelectedRoles((current) => {
      if (hasRole(roleName)) {
        return current.filter((role) => role !== roleName);
      }
      return [...current, roleName];
    });
  };

  const handleMenuClose = async () => {
    const addRoles = selectedRoles.filter((role) => !user.roles?.includes(role)) ?? [];
    const removeRoles = user.roles?.filter((role) => !selectedRoles.includes(role)) ?? [];

    if (removeRoles.length > 0) {
      await dispatch(removeUserRoles(user, removeRoles, toast));
    }

    if (addRoles.length > 0) {
      await dispatch(addUserRoles(user, addRoles, toast));
    }
  };

  useEffect(() => {
    setSelectedRoles(user.roles ?? []);
  }, [user.roles]);

  const buildButtonLabel = (
    userRoles: string[] = [],
    orgRolesParam: { groupName?: string }[] = [],
  ) => {
    const orgRoleNames = orgRolesParam.map((role) => role.groupName ?? undefined);
    const actualUserRoles = userRoles.filter((role) => orgRoleNames.includes(role));

    const rolesCount = actualUserRoles.length || 0;
    const hasMultipleRoles = rolesCount > 1;
    return hasMultipleRoles ? `${rolesCount} Roles` : actualUserRoles[0] || 'No Roles';
  };

  const buttonLabel = buildButtonLabel(selectedRoles, orgRoles);
  const isUserSysAdmin = useCheckPermissions([SYSTEM_ADMIN]);
  const cannotAssignSysAdmin = (roleName: string) => !isUserSysAdmin && roleName === SYS_ADMIN;

  return (
    <Menu
      id="assign-role"
      defaultLabel={buttonLabel}
      onClose={handleMenuClose}
      options={orgRoles.map((role) => {
        const { groupName } = role;
        return {
          id: groupName,
          label: groupName,
          isDisabled: (cannotAssignSysAdmin(groupName) || user?.archived),
          onClick: () => {
            if (user?.archived) return;
            if (!cannotAssignSysAdmin(groupName)) {
              handleRoleSelect(groupName);
            }
          },
          rightIcon: hasRole(groupName) ? CheckmarkFilledIcon : undefined,
        };
      })}
      menuButtonProps={{
        w: '120px',
      }}
      closeOnSelect={false}
    />
  );
};

export default AssignRole;
