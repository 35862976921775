// Auth
export const GET_USER_BY_OKTA_USER_ID = 'GET_USER_BY_OKTA_USER_ID';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';
export const DEAUTH_USER = 'DEAUTH_USER';
export const RESET_PASSWORD = 'RESET_EMAIL_SENT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const CLEAR_QUEUE_NOTIFICATION = 'CLEAR_QUEUE_NOTIFICATION';
export const CLEAR_NEW_MESSAGES = 'CLEAR_NEW_MESSAGES';
export const APPEND_CHAT_MESSAGES = 'APPEND_CHAT_MESSAGES';
export const PRESENT_MESSAGE = 'PRESENT_MESSAGE';

export const LOAD_ORG_OPERATORS = 'LOAD_ORG_OPERATORS';

export const UPDATE_PROFILE_PROPERTIES = 'UPDATE_PROFILE_PROPERTIES';
export const UPDATE_CHECKED_QUERY_PARAMETERS = 'UPDATE_CHECKED_QUERY_PARAMETERS';
export const INCREMENT_PREFETCH_CASES_COUNT = 'INCREMENT_PREFETCH_CASES_COUNT';

export const OPEN_DASHBOARD_VIEW = 'OPEN_DASHBOARD_VIEW';
export const SET_RIGHT_SIDEBAR_TAB = 'SET_RIGHT_SIDEBAR_TAB';

export const SET_TEMPLATE_TRAY_IS_OPEN = 'SET_TEMPLATE_TRAY_IS_OPEN';
export const SORT_TEMPLATES = 'SORT_TEMPLATES';
export const FILTER_TEMPLATES = 'FILTER_TEMPLATES';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const MUTE_AUDIO = 'MUTE_AUDIO';
export const AUDIO_NOTIFICATION = 'AUDIO_NOTIFICATION';

export const API_ERROR = 'API_ERROR';
export const MARLEY_CLAIMS_ERROR = 'MARLEY_CLAIMS_ERROR';
export const UPDATE_CLAIM_SENT = 'SEND_MARLEY_CLAIMS';
export const SET_ACTIVE_CLAIM = 'SET_ACTIVE_CLAIM';
export const ADD_CLAIM = 'ADD_CLAIM';
export const NEW_CLAIM_CREATED = 'NEW_CLAIM_CREATED';
export const CLAIM_IS_UPDATED = 'CLAIM_IS_UPDATED';
export const START_CLAIM_REQUEST = 'START_CLAIM_REQUEST';
export const CLAIMS_HTTP_ERROR = 'CLAIMS_HTTP_ERROR';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const ASSIGN_CLAIM = 'ASSIGN_CLAIM';

export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';

export const ASSIGN_USER = 'ASSIGN_USER';

export const CLOSE_CHAT = 'CLOSE_CHAT';
export const ADD_CHAT = 'ADD_CHAT';
export const LOAD_CHATS = 'LOAD_CHATS';
export const LOAD_CHAT_MESSAGES = 'LOAD_CHAT_MESSAGES';
export const CHAT_ADD_USER = 'CHAT_ADD_USER';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const GENERIC_MESSAGE = 'GENERIC_MESSAGE';
export const CHAT_SEND_FILE_STARTED = 'CHAT_SEND_FILE_STARTED';
export const CHAT_SEND_FILE_COMPLETED = 'CHAT_SEND_FILE_COMPLETED';
export const CHAT_SEND_FILE_ERROR = 'CHAT_SEND_FILE_ERROR';
export const CHAT_SEND_FILE_CLEAR = 'CHAT_SEND_FILE_CLEAR';
export const CHAT_FORWARD_MEDIA = 'CHAT_FORWARD_MEDIA';
export const SET_LAST_READ_CHATS = 'SET_LAST_READ_CHATS';
export const ENDUSER_JOINED_CHAT = 'ENDUSER_JOINED_CHAT';
export const CLEAN_SEND_SURVEY = 'CLEAN_SEND_SURVEY';
export const REQUEST_SEND_SURVEY_INIT = 'REQUEST_SEND_SURVEY_INIT';
export const REQUEST_SEND_SURVEY_SUCCESS = 'REQUEST_SEND_SURVEY_SUCCESS';
export const REQUEST_SEND_SURVEY_ERROR = 'REQUEST_SEND_SURVEY_ERROR';
export const RESEND_WELCOME_EMAIL_FOR_JOB_ERROR = 'RESEND_WELCOME_EMAIL_FOR_JOB_ERROR';
export const WELCOME_EMAIL_REQUEST_CLEAN = 'WELCOME_EMAIL_REQUEST_CLEAN';
export const WELCOME_EMAIL_IS_SENT = 'WELCOME_EMAIL_IS_SENT';

export const OPEN_PROFILE_CONFIG = 'OPEN_PROFILE_CONFIG';
export const CLOSE_PROFILE_CONFIG = 'CLOSE_PROFILE_CONFIG';
export const PROFILE_REQUEST_STARTED = 'PROFILE_REQUEST_STARTED';
export const SET_PROFILE = 'SET_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';
export const PUT_USER_AUTOASSIGN = 'PUT_USER_AUTOASSIGN';
export const OPEN_JOB = 'OPEN_JOB';
export const NEW_JOB_THEME_ASSIGNMENT = 'NEW_JOB_THEME_ASSIGNMENT';
export const LOAD_JOBS = 'LOAD_JOBS';
export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const UPDATE_JOB_CALLBACK = 'UDPATE_JOB_CALLBACK';
export const PUT_JOB = 'PUT_JOB';
export const PATCH_JOB = 'PATCH_JOB';
export const BULK_UPDATE_JOB = 'BULK_UPDATE_JOB';
export const UPDATE_JOB_SEARCH = 'UPDATE_JOB_SEARCH';
export const SET_EXPAND_SEARCH_BAR = 'SET_EXPAND_SEARCH_BAR';
export const UPDATE_SEARCH_RESULTS_COUNT = 'UPDATE_SEARCH_RESULTS_COUNT';
export const FETCH_CASE_TYPES = 'FETCH_CASE_TYPES';
export const READ_NOTE_MENTION = 'READ_NOTE_MENTION';
export const ADD_REPLY = 'ADD_REPLY';
export const DELETE_REPLY = 'DELETE_REPLY';
export const UPDATE_TOTAL_CHAT_COUNT = 'UPDATE_TOTAL_CHAT_COUNT';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_OPERATORS = 'LOAD_OPERATORS';
export const FETCH_LIST_OF_TITLES = 'FETCH_LIST_OF_TITLES';
export const NEW_USER = 'NEW_USER';
export const NEW_BULK_USER = 'NEW_BULK_USER';
export const ADD_USER = 'ADD_USER';
export const FILTER_USERS = 'FILTER_USERS';
export const UPDATED_USER_REQUEST_SUCCESS = 'UPDATED_USER_REQUEST_SUCCESS';
export const UPDATED_USER_REQUEST_ERROR = 'UPDATED_USER_REQUEST_ERROR';
export const UPDATED_USER_REQUEST_CLEAN = 'UPDATED_USER_REQUEST_CLEAN';
export const UPDATED_USER = 'UPDATED_USER';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE_UPDATE = 'CLEAR_PROFILE_UPDATE';
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE';
export const UPDATE_PROFILE_AUTOREPLY = 'UPDATE_PROFILE_AUTOREPLY';
export const SET_PROFILE_EDIT_MODE = 'SET_PROFILE_EDIT_MODE';
export const LOAD_CUSTOMER_FEEDBACK = 'LOAD_CUSTOMER_FEEDBACK';

export const OPEN_EDIT_PROFILE = 'OPEN_EDIT_PROFILE';
export const CLOSE_EDIT_PROFILE = 'CLOSE_EDIT_PROFILE';
export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD';

export const OPEN_CLAIMS_ENTRY = 'OPEN_CLAIMS_ENTRY';
export const OPEN_JOBS = 'OPEN_JOBS';
export const OPEN_CLAIMS = 'OPEN_CLAIMS';
export const OPEN_TEMPLATES = 'OPEN_TEMPLATES';
export const CLOSE_JOB_TAB = 'CLOSE_JOB_TAB';

export const SELECT_QUEUE = 'SELECT_QUEUE';
export const LOAD_QUEUES = 'LOAD_QUEUES';

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';
export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const MODIFY_CHAT_HEIGHT = 'MODIFY_CHAT_HEIGHT';

export const SORT_ITEMS = 'SORT_ITEMS';
export const FILTER_ITEMS = 'FILTER_ITEMS';

export const OPEN_INACTIVITY_ALERT = 'OPEN_INACTIVITY_ALERT';
export const CLOSE_INACTIVITY_ALERT = 'CLOSE_INACTIVITY_ALERT';

export const LOAD_MESSAGES_PER_USER = 'LOAD_MESSAGES_PER_USER';
export const LOAD_CUSTOMER_NOT_RESPOND = 'LOAD_CUSTOMER_NOT_RESPOND';
export const LOAD_INOUT_OPERATOR_MESSAGES = 'LOAD_INOUT_OPERATOR_MESSAGES';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_CONFIG_ITEM = 'SET_CONFIG_ITEM';
export const SET_CASE_SELECTOR = 'SET_CASE_SELECTOR';
export const ACTIVE_JOB = 'ACTIVE_JOB';
export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const SET_LAST_READ_CHAT = 'SET_LAST_READ_CHAT';
export const LOAD_PINNED_JOBS = 'LOAD_PINNED_JOBS';
export const PINNED_JOB = 'PINNED_JOB';
export const UNPINNED_JOB = 'UNPINNED_JOB';

export const LOAD_ANALYTICS_REPORTS = 'LOAD_ANALYTICS_REPORTS';

export const LOAD_LOOKER_URL = 'LOAD_LOOKER_URL';
export const LOAD_LOOK_RESULT = 'LOAD_LOOK_RESULT';
export const LOAD_IFRAME_LOOK_URL = 'LOAD_IFRAME_LOOK_URL';
export const LOAD_LOOK_CASE_IDS = 'LOAD_LOOK_CASE_IDS';

export const UPDATE_CHAT_MESSAGES = 'UPDATE_CHAT_MESSAGES';
export const UPDATE_UNREAD_MESSAGES = 'UPDATE_UNREAD_MESSAGES';

export const REOPEN_CASE_METRIC = 'REOPEN_CASE_METRIC';
export const CLOSE_CASE_METRIC = 'CLOSE_CASE_METRIC';

export const GET_ORGANIZATION_ROLES = 'GET_ORGANIZATION_ROLES';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const BULK_UPDATE_USER_ROLES = 'BULK_UPDATE_USER_ROLES'; // for bulk uploading users from csv
export const DELETE_USER = 'DELETE_USER';
export const RESET_BULK_UPLOAD_PROGRESS_COUNT = 'RESET_BULK_UPLOAD_PROGRESS_COUNT';
export const UPDATE_ORGANIZATION_BRANDING = 'UPDATE_ORGANIZATION_BRANDING';
export const UPDATE_ORGANIZATION_SETTING = 'UPDATE_ORGANIZATION_SETTING';
export const UPDATE_LINE_OF_BUSINESS_ACTION = 'UPDATE_LINE_OF_BUSINESS_ACTION';
export const GET_FULL_ORGANIZATION_DATA = 'GET_FULL_ORGANIZATION_DATA';
export const UPDATE_WELCOME_FLOW = 'UPDATE_WELCOME_FLOW';
export const SET_IS_UPDATING_ORG_SETTING = 'SET_IS_UPDATING_ORG_SETTING';
export const SET_IS_UPDATING_LINE_OF_BUSINESS = 'SET_IS_UPDATING_LINE_OF_BUSINESS';
export const IS_GETTING_FULL_ORGANIZATION_DATA = 'IS_GETTING_FULL_ORGANIZATION_DATA';
export const SET_IS_UPDATING_BRANDING = 'SET_IS_UPDATING_BRANDING';

export const SET_SOCKET = 'SET_SOCKET';

export const LOAD_CHAT_PARTICIPANTS = 'LOAD_CHAT_PARTICIPANTS';
export const LOAD_ALL_CHAT_PARTICIPANTS = 'LOAD_ALL_CHAT_PARTICIPANTS';

export const SUBSCRIBE_TO_ORG = 'SUBSCRIBE_TO_ORG';
export const SUBSCRIBE_TO_TOPICS = 'SUBSCRIBE_TO_TOPICS';
export const UNSUBSCRIBE_FROM_TOPICS = 'UNSUBSCRIBE_FROM_TOPICS';

export const HIDE_CHATLOG_BUTTONS = 'HIDE_CHATLOG_BUTTONS';
export const SHOW_CHATLOG_BUTTONS = 'SHOW_CHATLOG_BUTTONS';

export const CHAT_BOT_TAKE_OVER = 'CHAT_BOT_TAKE_OVER';
export const CHAT_BOT_TAKE_OVER_ERROR = 'CHAT_BOT_TAKE_OVER_ERROR';
export const GET_MASS_NOTIFICATIONS_ACTION = 'GET_MASS_NOTIFICAITIONS_ACTION';
export const HIDE_MASSNOTIFICATION_MODAL = 'HIDE_MASSNOTIFICATION_MODAL';
export const SET_MASSNOTIFICATION_FILENAME = 'SET_MASSNOTIFICATION_FILENAME';
export const SET_CREATE_BUTTON_STATUS = 'SET_CREATE_BUTTON_STATUS';
export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';
export const SET_SELECTED_TYPE = 'SET_SELECTED_TYPE';
export const CREATED_MASS_NOTIFICATION = 'CREATE_MASSNOTIFICATION';
export const FAILED_TO_CREATE_NOTIFICATION = 'FAILED_TO_CREATE_NOTIFICATION';
export const MASS_NOTIFICATION_UPDATE = 'MASS_NOTIFICATION_UPDATE';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const SET_MESSAGE_COPIED = 'SET_MESSAGE_COPIED';
export const MASS_NOTIFICATION_ASSIGNED = 'MASS_NOTIFICATION_ASSIGNED';
export const SET_MASS_NOTE_DELETE_MODAL_SHOW = 'SET_MASS_NOTE_DELETE_MODAL_SHOW';
export const MASS_NOTIFICATION_PROGRESS_UPDATE = 'MASS_NOTIFICATION_PROGRESS_UPDATE';
export const MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE = 'MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE';
export const CREATE_NOTE = 'CREATE_NOTE';
export const SET_LEFT_BAR_BLUE_DOT = 'SET_LEFT_BAR_BLUE_DOT';
export const DELETE_NOTE = 'DELETE_NOTE';
export const SHOW_SEND_FILE_MODAL = 'SHOW_SEND_FILE_MODAL';
export const UPDATE_EMAIL_CONFIG = 'UPDATE_EMAIL_CONFIG';
export const SET_EMAIL_CONFIG = 'SET_EMAIL_CONFIG';

export const RESET_EDIT_OBJECT = 'RESET_EDIT_OBJECT';
export const CLEAR_USERS = 'CLEAR_USERS';

export const LOAD_CASES = 'LOAD_CASES';
export const SET_ACTIVE_CHAT_FIRST_KEY_STROKE = 'SET_ACTIVE_CHAT_FIRST_KEY_STROKE';
export const USER_IS_TYPING = 'USER_IS_TYPING';
export const USER_IS_TYPING_TIMEOUT = 'USER_IS_TYPING_TIMEOUT';
export const REQUEST_PUT_JOB_SUCCESS = 'REQUEST_PUT_JOB_SUCCESS';
export const UPDATE_LOCK_PROGRESS = 'UPDATE_LOCK_PROGRESS';
export const FETCH_FILTERED_OPERATOR_IDS = 'FETCH_FILTERED_OPERATOR_IDS';
export const REQUEST_GET_JOBS_ERROR = 'REQUEST_GET_JOBS_ERROR';

export const INIT_LOAD_DONE = 'INIT_LOAD_DONE';
export const LIVE_UPDATE_STRATEGY_SELECTED = 'LIVE_UPDATE_STRATEGY_SELECTED';
export const SET_SOCKET_FAILURE = 'SET_SOCKET_FAILURE';

export const FAILED_TO_MAKE_MANDATORY_PAGE_INIT_NETWORK_CALLS = 'FAILED_TO_MAKE_MANDATORY_PAGE_INIT_NETWORK_CALLS';

export const RESET_PROFILE_REQUESTS = 'RESET_PROFILE_REQUESTS';
export const REQUEST_GET_USERS_AUTOREPLIES_INIT = 'REQUEST_GET_USERS_AUTOREPLIES_INIT';
export const REQUEST_GET_USERS_AUTOREPLIES_SUCCESS = 'REQUEST_GET_USERS_AUTOREPLIES_SUCCESS';
export const REQUEST_GET_USERS_AUTOREPLIES_ERROR = 'REQUEST_GET_USERS_AUTOREPLIES_ERROR';
export const GET_USERS_AUTOREPLIES = 'GET_USERS_AUTOREPLIES';
export const GET_ORGANIZATIONS_AUTOREPLIES = 'GET_ORGANIZATIONS_AUTOREPLIES';
export const PUT_ORGANIZATIONS_AUTOREPLIES = 'PUT_ORGANIZATIONS_AUTOREPLIES';
export const POST_ORGANIZATIONS_AUTOREPLIES = 'POST_ORGANIZATIONS_AUTOREPLIES';
export const REQUEST_POST_USERS_AUTOREPLIES_INIT = 'REQUEST_POST_USERS_AUTOREPLIES_INIT';
export const REQUEST_POST_USERS_AUTOREPLIES_SUCCESS = 'REQUEST_POST_USERS_AUTOREPLIES_SUCCESS';
export const REQUEST_POST_USERS_AUTOREPLIES_ERROR = 'REQUEST_POST_USERS_AUTOREPLIES_ERROR';
export const POST_USERS_AUTOREPLIES = 'POST_USERS_AUTOREPLIES';
export const REQUEST_PUT_USERS_AUTOREPLIES_INIT = 'REQUEST_PUT_USERS_AUTOREPLIES_INIT';
export const REQUEST_PUT_USERS_AUTOREPLIES_SUCCESS = 'REQUEST_PUT_USERS_AUTOREPLIES_SUCCESS';
export const REQUEST_PUT_USERS_AUTOREPLIES_ERROR = 'REQUEST_PUT_USERS_AUTOREPLIES_ERROR';
export const PUT_USERS_AUTOREPLIES = 'PUT_USERS_AUTOREPLIES';
export const REQUEST_DELETE_USERS_AUTOREPLIES_INIT = 'REQUEST_DELETE_USERS_AUTOREPLIES_INIT';
export const REQUEST_DELETE_USERS_AUTOREPLIES_SUCCESS = 'REQUEST_DELETE_USERS_AUTOREPLIES_SUCCESS';
export const REQUEST_DELETE_USERS_AUTOREPLIES_ERROR = 'REQUEST_DELETE_USERS_AUTOREPLIES_ERROR';
export const DELETE_USERS_AUTOREPLIES = 'DELETE_USERS_AUTOREPLIES';
export const DISABLE_NEXT_BTN = 'DISABLE_NEXT_BTN';

export const UPDATE_USER = 'UPDATE_USER';
export const SET_UPDATE_USER_STATUS = 'SET_UPDATE_USER_STATUS';
export const CANCEL_SCHEDULED_MESSAGE = 'CANCEL_SCHEDULED_MESSAGE';
export const INLINE_MESSAGE_UPDATE = 'INLINE_MESSAGE_UPDATE';
export const INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT = 'INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT';
export const PUT_MESSAGE_NEEDS_ACTION_RESPONSE = 'PUT_MESSAGE_NEEDS_ACTION_RESPONSE';
export const INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT = 'INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT';
export const UNREDACTED_MESSAGE = 'UNREDACTED_MESSAGE';
export const CLEAR_UNREDACTED_MESSAGE = 'CLEAR_UNREDACTED_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const PUT_JOB_NEEDS_ACTION_RESPONSE = 'PUT_JOB_NEEDS_ACTION_RESPONSE';
export const SET_ACTIVE_OPERATOR_COUNT = 'SET_ACTIVE_OPERATOR_COUNT';
export const UPDATE_ACTIVE_OPERATOR_COUNT = 'UPDATE_ACTIVE_OPERATOR_COUNT';
export const IS_FETCHING_ACTIVE_OPERATOR_COUNT = 'IS_FETCHING_ACTIVE_OPERATOR_COUNT';

export const OPEN_MEDIA_MODAL = 'OPEN_MEDIA_MODAL';
export const CLOSE_MEDIA_MODAL = 'CLOSE_MEDIA_MODAL';
export const UPDATE_MEDIA_MODAL = 'UPDATE_MEDIA_MODAL';

export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const REDACT_IMAGE = 'REDACT_IMAGE';
export const VIEW_REDACTED_IMAGE = 'VIEW_REDACTED_IMAGE';
export const UPDATE_NEW_INBOX_CARD_DENSITY = 'UPDATE_NEW_INBOX_CARD_DENSITY';
export const UPDATE_VIRUS_SCAN_STATUS = 'UPDATE_VIRUS_SCAN_STATUS';

export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const EXIT_ACTIVE_WORKFLOW = 'EXIT_ACTIVE_WORKFLOW';

export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';
export const UPDATE_OPERATOR_ARCHIVED_STATE = 'UPDATE_OPERATOR_ARCHIVED_STATE';
