import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Spinner,
  Flex,
  Text,
  Icon,
} from '@chakra-ui/react';
import {
  InfoCircleIcon,
  TrianglesUpDownFilledDownIcon,
  TrianglesUpDownFilledUpIcon,
  TrianglesUpDownIcon,
} from '@himarley/unity';
import React, { useRef, useCallback } from 'react';

interface SortProps {
  sortOptions: { order: string; column: string }[];
  selectedSort: { order: string; column: string };
}

const Sort: React.FC<SortProps> = ({ sortOptions, selectedSort }) => {
  const isAscSorted = selectedSort.column === sortOptions[0].column
    && selectedSort.order === sortOptions[0].order;
  const isDescSorted = selectedSort.column === sortOptions[1].column
    && selectedSort.order === sortOptions[1].order;

  let icon = TrianglesUpDownIcon;
  if (isAscSorted) icon = TrianglesUpDownFilledUpIcon;
  if (isDescSorted) icon = TrianglesUpDownFilledDownIcon;
  return <Icon as={icon} boxSize="14px" sx={{ marginLeft: '4px' }} />;
};

interface TableProps {
  type: string;
  rows: React.ReactNode[];
  columns: {
    id: string;
    label: string;
    sort?: { order: string; column: string }[];
  }[];
  increaseDataSet: () => void;
  label: string;
  onSort: (sort: { order: string; column: string }) => void;
  selectedSort: {
    order: string;
    column: string;
  };
  shouldLoadMoreItems?: boolean;
  testId?: string;
  loadedItem?: boolean;
}

const Table: React.FC<TableProps> = ({
  type,
  columns,
  rows,
  increaseDataSet,
  label: labelProp,
  onSort,
  selectedSort,
  shouldLoadMoreItems = false,
  testId = 'chakra-table',
  loadedItem = true,
}) => {
  const noneFound = (label: string) => `We're looking, but no ${label} found.`;
  const label = type ? type.toLowerCase() : labelProp;
  const observer = useRef<IntersectionObserver | null>(null);

  const lastPostElementRef = useCallback(
    (node: Element | null) => {
      if (!shouldLoadMoreItems) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          increaseDataSet();
        }
      });

      if (node) observer.current?.observe(node);
    },
    [increaseDataSet, shouldLoadMoreItems],
  );

  if (rows?.length === 0) {
    return (
      <Flex
        alignItems="center"
        gap={5}
        alignContent="center"
        justifyContent="center"
      >
        <Icon as={InfoCircleIcon} />
        <Text>{noneFound(label)}</Text>
      </Flex>
    );
  }

  const handleSort = (sortOptions: { order: string; column: string }[]) => {
    if (!sortOptions) return;
    const isAscSorted = selectedSort.column === sortOptions[0].column
      && selectedSort.order === sortOptions[0].order;
    onSort(sortOptions[isAscSorted ? 1 : 0]);
  };

  const getTableHeaderTestId = (sortOptions: { order: string; column: string }[]) => {
    const isAscSorted = selectedSort.column === sortOptions[0].column
      && selectedSort.order === sortOptions[0].order;
    const isDescSorted = selectedSort.column === sortOptions[1].column
      && selectedSort.order === sortOptions[1].order;
    if (isDescSorted) {
      return `${sortOptions[1].column}-desc-sort`;
    }
    if (isAscSorted) {
      return `${sortOptions[0].column}-asc-sort`;
    }
    return '';
  };

  return (
    <TableContainer w="100%" h="100vh" overflowY="auto">
      <ChakraTable variant="simple" data-testid={testId}>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th
                key={column.id}
                onClick={() => column.sort && handleSort(column.sort)}
                data-testid={column.sort ? getTableHeaderTestId(column.sort) : undefined}
                sx={{ _hover: column.sort ? { backgroundColor: 'gray.50', cursor: 'pointer' } : '' }}
              >
                <Flex alignItems="center">
                  {column?.label}

                  {column.sort && (
                    <Sort
                      sortOptions={column.sort}
                      selectedSort={selectedSort}
                    />
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows}
          {!loadedItem ? (
            <Tr>
              <Flex
                alignItems="center"
                alignContent="center"
                justifyContent="center"
              >
                <Spinner />
              </Flex>
            </Tr>
          ) : (
            <div ref={lastPostElementRef} />
          )}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};

export default Table;
