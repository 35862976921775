import { fetchData } from '@app/hooks/react-query-helpers';

interface FetchGroupsParams {
  offset: number;
  limit: number;
  searchText?: string;
}

export const fetchGroups = async ({
  offset,
  limit,
  searchText,
}: FetchGroupsParams) => {
  const queryParams = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  });

  if (searchText) {
    queryParams.append('searchText', searchText);
  }

  return fetchData(`/api/groups?${queryParams.toString()}`);
};

export default fetchGroups;
