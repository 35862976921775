import {
  Tabs, TabList, Tab, TabPanels, TabPanel, Box,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface TabConfig {
  id: string;
  title: string;
  component: React.ReactNode;
}

interface TabRouterProps {
  tabList: TabConfig[];
  basePath: string;
  defaultTabId?: string;
  onTabChange?: (tabId: string) => void;
  className?: string;
}

const TabRouter: React.FC<TabRouterProps> = ({
  tabList,
  basePath,
  defaultTabId,
  onTabChange,
  className,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    if (defaultTabId) {
      const index = tabList.findIndex((tab) => tab.id === defaultTabId);
      return index > -1 ? index : 0;
    }
    return 0;
  });

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const baseIndex = pathSegments.findIndex((segment) => segment === basePath);
    const activeTab = pathSegments[baseIndex + 1];

    if (activeTab) {
      const currentTabIndex = tabList.findIndex((tab) => tab.id === activeTab);
      if (currentTabIndex > -1) {
        setSelectedTabIndex(currentTabIndex);
        onTabChange?.(activeTab);
      } else {
        // Invalid tab ID - redirect to default or first tab
        const defaultIndex = defaultTabId
          ? tabList.findIndex((tab) => tab.id === defaultTabId)
          : 0;
        if (defaultIndex > -1) {
          history.replace(`/${basePath}/${tabList[defaultIndex].id}`);
        }
      }
    } else if (tabList.length > 0) {
      // No tab in URL - redirect to default or first tab
      const defaultIndex = defaultTabId
        ? tabList.findIndex((tab) => tab.id === defaultTabId)
        : 0;
      if (defaultIndex > -1) {
        history.replace(`/${basePath}/${tabList[defaultIndex].id}`);
      }
    }
  }, [location.pathname, tabList, history, basePath, defaultTabId, onTabChange]);

  const handleTabSelect = (index: number) => {
    if (tabList[index]) {
      const tab = tabList[index].id;
      onTabChange?.(tab);
      history.push(`/${basePath}/${tab}`);
    }
  };

  // Only render if we have tabs
  if (!tabList.length) {
    return null;
  }

  return (
    <Box height="100%" width="100%" overflow="hidden">
      <Tabs
        index={selectedTabIndex}
        onChange={handleTabSelect}
        className={className}
        isLazy
        lazyBehavior="keepMounted"
        variant="marley"
      >
        <TabList>
          {tabList.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              data-testid={`tab-item-${tab.id}`}
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabList.map((tab) => (
            <TabPanel key={tab.id}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabRouter;
