/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  TrayContainer,
  Badge,
  ToggleButtonGroup,
  RadioGroup,
  Tooltip,
  InfoCircleIcon,
} from '@himarley/unity';
import { persistUserProperties } from '@app/actions/profile';
import classnames from 'classnames';
import MoneyCashIcon from '../../../../../../images/icons/moneyCash.svg';
import BrandLoBSelect from './BrandLoBSelect';
import QuickFilter from '../QuickFilter/quick-filter';
import styles from './FilterTray.module.less';
import * as constants from './constants';
import { getSelectedTagIds } from './helpers';
import {
  REFINE_BY_ITEMS,
  REFINE_BY,
} from '../../../../../constants/inboxFilters';
import TagsSelect from './TagsSelect';

constants.ALL_BRANDS[0].avatar = <MoneyCashIcon />;
constants.ALL_LOBS[0].avatar = <MoneyCashIcon />;
const IS_BRANDS_LOB_SHOWN = false;

const FilterTray = ({
  onCancel,
  brands = [],
  lobs = [],
  isOpen,
  triggerRef,
}) => {
  const { inboxFilters, permissions, hasUsedNewInboxFilters } = useSelector((state) => ({
    inboxFilters: state?.profile?.properties?.inboxFilters || {},
    permissions: state?.auth?.permissions || [],
    hasUsedNewInboxFilters:
        state?.profile?.properties?.hasUsedNewInboxFilters || false,
  }));
  const dispatch = useDispatch();
  const componentRef = useRef(null);

  const [selectedLobs, setSelectedLobs] = useState(
    constants.DEFAULT_SELECTED_LOB,
  );
  const [selectedBrands, setSelectedBrands] = useState(
    constants.DEFAULT_SELECTED_BRAND,
  );
  const [selectedAssignees, setSelectedAssignees] = useState(new Map());
  const [caseStatus, setCaseStatus] = useState(
    inboxFilters?.onlyActive ? constants.ACTIVE_CASES : constants.ALL_CASES,
  );
  const [refineBy, setRefineBy] = useState(REFINE_BY.NONE);
  const [isResettingAssignees, setIsResettingAssignees] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [tags, setTags] = useState(new Map());
  const [showFilterCountBadge, setShowFilterCountBadge] = useState(false);

  useEffect(() => {
    setTags(constants.getValidTags(permissions));
  }, [permissions]);

  const prePopulateTags = useCallback(() => {
    let preSelectedTags = new Map(constants.TAGS);
    if (inboxFilters?.tags?.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      if (inboxFilters?.refineBy === REFINE_BY.MENTIONS) {
        preSelectedTags = constants.toggleTagsDisabledState(true, permissions);
      } else {
        preSelectedTags = new Map(constants.getValidTags(permissions));
        for (const [key, { id }] of preSelectedTags) {
          if (inboxFilters?.tags?.includes(id)) {
            preSelectedTags.set(key, { id, isSelected: true });
          }
        }
      }
      setTags(preSelectedTags);
    } else {
      // default to all selected
      if (inboxFilters?.refineBy === REFINE_BY.MENTIONS) {
        preSelectedTags = constants.toggleTagsDisabledState(true, permissions);
      } else {
        preSelectedTags = new Map(constants.getValidTags(permissions));
        preSelectedTags.set(constants.ALL_TAG, { isSelected: true, id: 'all' });
      }
      setTags(preSelectedTags);
    }
  }, [inboxFilters?.refineBy, inboxFilters?.tags, permissions]);

  const prePopulateFilters = useCallback(() => {
    setCaseStatus(
      inboxFilters?.onlyActive ? constants.ACTIVE_CASES : constants.ALL_CASES,
    );
    prePopulateTags();
    setRefineBy(inboxFilters?.refineBy);
  }, [inboxFilters?.onlyActive, inboxFilters?.refineBy, prePopulateTags]);

  useEffect(() => {
    prePopulateFilters();
  }, [
    inboxFilters?.onlyActive,
    setCaseStatus,
    inboxFilters?.tags,
    setTags,
    prePopulateFilters,
    inboxFilters?.refineBy,
    inboxFilters?.secondaryOperatorIds,
  ]);

  const handleSave = useCallback(() => {
    dispatch(
      persistUserProperties({
        hasUsedNewInboxFilters: showFilterCountBadge,
        inboxFilters: {
          ...inboxFilters,
          ...selectedAssignees,
          tags: getSelectedTagIds(tags),
          onlyActive: caseStatus === constants.ACTIVE_CASES,
          refineBy,
        },
      }),
    );
    onCancel();
  }, [
    caseStatus,
    dispatch,
    inboxFilters,
    showFilterCountBadge,
    onCancel,
    refineBy,
    selectedAssignees,
    tags,
  ]);

  const handleResetFilters = useCallback(() => {
    setSelectedLobs(constants.DEFAULT_SELECTED_LOB);
    setSelectedBrands(constants.DEFAULT_SELECTED_BRAND);
    setCaseStatus(constants.ALL_CASES);
    setRefineBy(REFINE_BY.NONE);
    setIsResettingAssignees(true);
    const resetTags = new Map(constants.getValidTags(permissions));
    resetTags.set(constants.ALL_TAG, { isSelected: true, id: 'all' });
    setTags(resetTags);
    setShowFilterCountBadge(false);
  }, [permissions]);

  const handleCancel = useCallback(
    (e) => {
      if (!triggerRef?.current.contains(e?.target)) {
        setIsCancelling(true);
        prePopulateFilters();
        onCancel();
        setSelectedAssignees({});
      }
    },
    [onCancel, prePopulateFilters, triggerRef],
  );

  const brandMenuItems = useMemo(
    () => [
      ...constants.ALL_BRANDS,
      ...brands.map((brand, index) => ({
        avatar: (
          <Avatar circularDisplay className={styles.avatar} name={brand} />
        ),
        label: brand,
        id: index,
      })),
    ],
    [brands],
  );

  const loBItems = useMemo(
    () => [
      ...constants.ALL_LOBS,
      ...lobs.map((lob) => ({
        avatar: (
          <Avatar
            circularDisplay
            className={styles.avatar}
            name={lob?.displayName}
          />
        ),
        label: lob?.displayName,
        id: lob?._id,
      })),
    ],
    [lobs],
  );

  const handleAssigneesSelect = useCallback((selections) => {
    setSelectedAssignees(selections);
  }, []);

  const selectedAssigneesCount = useMemo(() => {
    let globalCount = 0;
    if (
      inboxFilters?.items
      && inboxFilters?.groups
      && inboxFilters?.operatorIds
    ) {
      globalCount = Object.keys(inboxFilters?.groups).length
        + Object.keys(inboxFilters?.operatorIds).length
        + Object.keys(inboxFilters?.items).length;
    }
    let localCount = null;
    if (
      selectedAssignees?.items
      && selectedAssignees?.groups
      && selectedAssignees?.operatorIds
    ) {
      localCount = Object.keys(selectedAssignees?.groups).length
        + Object.keys(selectedAssignees?.operatorIds).length
        + Object.keys(selectedAssignees?.items).length;
    }
    return localCount ?? globalCount;
  }, [
    inboxFilters?.groups,
    inboxFilters?.items,
    inboxFilters?.operatorIds,
    selectedAssignees?.groups,
    selectedAssignees?.items,
    selectedAssignees?.operatorIds,
  ]);

  const handleMessageTypeSelect = useCallback(
    (type) => {
      if (type === REFINE_BY.MENTIONS) {
        setIsResettingAssignees(true); // set to All My Cases
        setTags(constants.toggleTagsDisabledState(true, permissions));
      } else {
        setTags(constants.toggleTagsDisabledState(false, permissions));
      }
      setRefineBy(type);
      setShowFilterCountBadge(true);
    },
    [permissions],
  );

  const selectedTagsCount = useMemo(() => {
    let count = 0;
    for (const [, val] of tags) {
      if (val?.isSelected) {
        count += 1;
      }
    }
    return count;
  }, [tags]);

  const trayContainerClasses = classnames(styles.container, {
    [styles.containerHidden]: isOpen === null,
    'animation-slide-left': isOpen !== false,
    'animation-slide-dismiss': !(isOpen === null || isOpen !== false),
  });

  return (
    isOpen !== null && (
      <TrayContainer
        className={trayContainerClasses}
        onCancel={handleCancel}
        onSave={handleSave}
        saveButtonText="Apply"
        title="Filters"
        footerLabel="Reset Filters"
        footerInfoLabelClick={handleResetFilters}
        footerClassName={styles.footer}
        bodyClassName={styles.body}
        triggerNode={triggerRef?.current}
      >
        <section className={styles.content} ref={componentRef}>
          <section>
            <div className={styles.filterCount}>
              <p className={styles.label}>CASE STATUS</p>
              <Tooltip
                trigger={<InfoCircleIcon />}
                tooltip="On active cases the customer has opted-in to texting and an assignee has responded to the initial text on their case."
              />
              {caseStatus === constants.ACTIVE_CASES && (
                <Badge value={1} testId="caseStatusCount" />
              )}
            </div>
            <ToggleButtonGroup
              className={styles.toggle}
              items={constants.CASE_STATUS_ITEMS}
              value={caseStatus}
              onValueChange={(val) => {
                if (!val) return;
                setCaseStatus(val);
              }}
            />
          </section>
          {IS_BRANDS_LOB_SHOWN && (
            <>
              <section>
                <div className={styles.labelActions}>
                  <div>
                    <p className={styles.label}>INSURANCE BRANDS</p>
                    <Badge value={selectedBrands.size} />
                  </div>
                  <button
                    onClick={() => setSelectedBrands(constants.DEFAULT_SELECTED_BRAND)}
                    type="button"
                  >
                    Reset
                  </button>
                </div>
                <BrandLoBSelect
                  menuItems={brandMenuItems}
                  setSelectedItems={setSelectedBrands}
                  selectedItems={selectedBrands}
                  container={componentRef}
                />
              </section>

              <section>
                <div className={styles.labelActions}>
                  <div>
                    <p className={styles.label}>LINES OF BUSINESS</p>
                    <Badge value={selectedLobs.size} />
                  </div>
                  <button
                    onClick={() => setSelectedLobs(constants.DEFAULT_SELECTED_LOB)}
                    type="button"
                  >
                    Reset
                  </button>
                </div>
                <BrandLoBSelect
                  menuItems={loBItems}
                  setSelectedItems={setSelectedLobs}
                  selectedItems={selectedLobs}
                  container={componentRef}
                />
              </section>
            </>
          )}
          <section>
            <div className={styles.filterCount}>
              <p className={styles.label}>MESSAGE TYPE</p>
              {refineBy && refineBy !== REFINE_BY.NONE && (
                <Badge value={1} testId="messageTypeCount" />
              )}
            </div>
            <RadioGroup
              className={styles.radioGroup}
              items={REFINE_BY_ITEMS}
              ariaLabel="message type"
              testId="refineBy"
              defaultValue={REFINE_BY.NONE}
              onValueChange={handleMessageTypeSelect}
              value={refineBy}
            />
            {refineBy === REFINE_BY.MENTIONS && (
              <div className={styles.mentionsInfo}>
                This message type will only show you @ mentions on cases. You
                will need to change your message type before you can apply other
                filters again.
              </div>
            )}
          </section>
          <section className={styles.assigneeSection}>
            <div className={styles.labelActions}>
              <div>
                <p className={styles.label}>ASSIGNEES</p>
                {(
                  refineBy !== REFINE_BY.MENTIONS
                && (hasUsedNewInboxFilters || showFilterCountBadge))
                && (
                <Badge
                  // by default primary and secondary are select so 2
                  value={selectedAssigneesCount || 2}
                  testId="assigneeCount"
                />
                )}
              </div>
              <button
                onClick={() => setIsResettingAssignees(true)}
                type="button"
              >
                Reset
              </button>
            </div>
            <QuickFilter
              container={componentRef}
              isResettingAssignees={isResettingAssignees}
              setIsResettingAssignees={setIsResettingAssignees}
              dropDownContentClass={styles.assigneeDropdown}
              triggerClassName={styles.assigneeTrigger}
              onCloseFilter={(selectionsParam) => {
                handleAssigneesSelect(selectionsParam);
                if (!isResettingAssignees) {
                  setShowFilterCountBadge(true);
                }
              }}
              isCancelling={isCancelling}
              setIsCancelling={setIsCancelling}
              testId="filterTrayAssigneeTrigger"
              disabled={refineBy === REFINE_BY.MENTIONS}
            />
          </section>
          <section>
            <div className={styles.filterCount}>
              <p className={styles.label}>TAGS</p>
              {!tags?.get('All')?.isSelected && (
                <Badge value={selectedTagsCount} testId="tagsCount" />
              )}
            </div>
            <TagsSelect
              tags={tags}
              setTags={(selectionsParam) => {
                setTags(selectionsParam);
                setShowFilterCountBadge(true);
              }}
            />
          </section>
        </section>
      </TrayContainer>
    )
  );
};

FilterTray.propTypes = {
  onCancel: PropTypes.func.isRequired,
  brands: PropTypes.string.isRequired,
  lobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool,
  triggerRef: PropTypes.node,
};

FilterTray.defaultProps = {
  isOpen: false,
  triggerRef: null,
};

export default FilterTray;
