import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeApiRequest } from '@app/actions/api/api';
import TabRouter from '@app/components/chakra/tab-router';
import {
  ORG_SETTINGS_WELCOME_FLOW, ADMIN_NOTIFICATION_CONFIG,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  FEATURE_FLAG_VERISK,
} from '@app/constants/permissions';
import usePermissionVerify from '@app/hooks/permission-verify';
import type { StateType } from '@app/types/reducer-state';

import AdminNotificationConfigurations from './AdminNotificationConfigurations/AdminNotificationConfigurations';
import CompanyHierarchy from './CompanyHierarchy/CompanyHierarchy';
import IntegrationSettingsContainer from './IntegrationSettings/integration-settings-container';
import OptInMessages from './OptInMessages/OptInMessages';
import OrgSettings from './OrgSettings/OrgSettings';
import WelcomeFlowSettings from './WelcomeFlowSettings/welcome-flow-settings';
import './Settings.less';

const NAV_EVENT_KEYS: { [key: string]: string } = {
  orgSettings: 'orgSettings',
  optInMessages: 'optInMessages',
  welcomeMessages: 'welcomeMessages',
  companyHierarchy: 'companyHierarchy',
  adminNotificationConfigurations: 'adminNotificationConfigurations',
  integrationSettings: 'integrationSettings',
};

const Settings = () => {
  const dispatch = useDispatch();

  const { organization, welcomeFlow } = useSelector(({ organizations }: StateType) => ({
    organization: organizations,
    welcomeFlow: organizations?.welcomeFlow || {},
  }));
  useEffect(() => {
    dispatch(makeApiRequest('GET_ORGANIZATIONS_AUTOREPLIES', [organization?.name?.toUpperCase()]));
  }, [dispatch, organization?.name]);

  const {
    [ORG_SETTINGS_WELCOME_FLOW]: canViewWelcomeFlow,
    [ADMIN_NOTIFICATION_CONFIG]: hasNotificationSettingsAccess,
    [FEATURE_FLAG_TOTAL_LOSS_ASSIST]: hasTLAAccess,
    [FEATURE_FLAG_VERISK]: hasVeriskAccess,
  } = usePermissionVerify(
    [
      ORG_SETTINGS_WELCOME_FLOW,
      ADMIN_NOTIFICATION_CONFIG,
      FEATURE_FLAG_TOTAL_LOSS_ASSIST,
      FEATURE_FLAG_VERISK,
    ],
  );

  const hasIntegrationsAccess = hasVeriskAccess || hasTLAAccess;

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;

  const tabList = useMemo(() => {
    const list = [
      {
        id: NAV_EVENT_KEYS.orgSettings,
        title: 'Org. Settings',
        component: <OrgSettings organization={organization} />,
      },
      {
        id: NAV_EVENT_KEYS.companyHierarchy,
        title: 'Company Hierarchy',
        component: <CompanyHierarchy organization={organization} />,
      },
    ];

    if (canViewWelcomeFlow) {
      if (isWelcomeFlowRefactorEnabled) {
        list.push({
          id: NAV_EVENT_KEYS.welcomeMessages,
          title: 'Welcome Messages',
          component: <WelcomeFlowSettings />,
        });
      } else {
        list.push({
          id: NAV_EVENT_KEYS.optInMessages,
          title: 'Opt-In Messages',
          component: <OptInMessages dispatch={dispatch} welcomeFlow={welcomeFlow} />,
        });
      }
    }

    if (hasNotificationSettingsAccess) {
      list.push({
        id: NAV_EVENT_KEYS?.adminNotificationConfigurations,
        title: 'Notifications',
        component: <AdminNotificationConfigurations />,
      });
    }

    if (hasIntegrationsAccess) {
      list.push({
        id: NAV_EVENT_KEYS?.integrationSettings,
        title: 'Integration Settings',
        component: <IntegrationSettingsContainer />,
      });
    }

    return list;
  }, [
    canViewWelcomeFlow,
    dispatch,
    hasIntegrationsAccess,
    hasNotificationSettingsAccess,
    isWelcomeFlowRefactorEnabled,
    organization,
    welcomeFlow,
  ]);

  return (
    <main data-testid="settingsWrap" className="settingsWrap">
      <TabRouter
        tabList={tabList}
        basePath="settings"
        defaultTabId={NAV_EVENT_KEYS.orgSettings}
        className="settingsWrap"
      />
    </main>
  );
};

export default Settings;
