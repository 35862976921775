import _ from 'lodash';
import * as commonReducer from './common';
import {
  UPDATE_PROFILE,
  BULK_UPDATE_USER_ROLES,
  GET_USER_ROLES,
  UPDATE_OPERATOR_ARCHIVED_STATE,
  UPDATE_USER_ROLE,
  LOAD_ORG_OPERATORS,
  NEW_USER,
  NEW_BULK_USER,
  RESET_BULK_UPLOAD_PROGRESS_COUNT,
  FETCH_LIST_OF_TITLES,
  SET_ACTIVE_OPERATOR_COUNT,
  UPDATE_ACTIVE_OPERATOR_COUNT,
  IS_FETCHING_ACTIVE_OPERATOR_COUNT,
  UPDATE_USER_GROUPS,
} from '../constants/actions';
import { updateListItemById } from './helpers';

export const initialState = {
  ...commonReducer.initialState,
  listOfTitles: [],
  activeOperatorCount: 0,
  isFetchingActiveOperatorCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ROLES: {
      let userRoles = _.get(action, 'payload', []);
      let list = _.get(state, 'list', []).slice();
      userRoles = userRoles.map((user) => (user.Item !== undefined ? user.Item : {}));
      list = list.map((user) => {
        const newRoles = _.get(
          _.find(userRoles, (r) => r.identityId === user.id),
          'groups',
        );
        if (newRoles) {
          user.roles = newRoles;
        }
        return user;
      });
      return { ...state, list };
    }
    case UPDATE_OPERATOR_ARCHIVED_STATE: {
      const { id, archived } = action.payload;
      const list = state.list.map((o) => {
        if (o.id === id) {
          return { ...o, archived };
        }
        return o;
      });
      return { ...state, list };
    }
    case UPDATE_USER_ROLE: {
      const userId = _.get(action, 'payload.userId');
      const roles = _.get(action, 'payload.roles');
      const list = state.list.map((o) => {
        if (o.id === userId) {
          return { ...o, roles };
        }
        return o;
      });
      return { ...state, list };
    }
    case LOAD_ORG_OPERATORS: {
      return {
        ...state,
        fullList: _.get(action, 'payload.data.operators', []),
      };
    }
    case NEW_USER: {
      const user = _.get(action, 'payload');
      const changes = {};
      if (user?.roles?.length) {
        changes.list = [...state.list, user];
      }
      return { ...state, ...changes };
    }
    case NEW_BULK_USER: {
      return {
        ...state,
        bulkUploadProgressCount: state.bulkUploadProgressCount + 1,
      };
    }
    case RESET_BULK_UPLOAD_PROGRESS_COUNT: {
      return {
        ...state,
        bulkUploadProgressCount: 0,
      };
    }
    case UPDATE_PROFILE: {
      const list = updateListItemById(state.list, action.payload);
      return {
        ...state,
        list,
      };
    }
    case BULK_UPDATE_USER_ROLES: {
      const userRolesMap = action.payload;
      const list = state.list.map((operator) => {
        if (userRolesMap[operator.id]) {
          return { ...operator, roles: userRolesMap[operator.id] };
        }
        return operator;
      });
      return { ...state, list };
    }
    case FETCH_LIST_OF_TITLES: {
      return { ...state, listOfTitles: _.get(action, 'payload.body') };
    }

    case SET_ACTIVE_OPERATOR_COUNT: {
      const payload = action?.payload || {};
      return {
        ...state,
        activeOperatorCount: payload?.body,
      };
    }

    case UPDATE_ACTIVE_OPERATOR_COUNT: {
      const payload = action?.payload || {};
      const newCount = state.activeOperatorCount + payload.count;
      return {
        ...state,
        activeOperatorCount: newCount,
      };
    }
    case IS_FETCHING_ACTIVE_OPERATOR_COUNT: {
      const payload = action?.payload || {};
      return {
        ...state,
        isFetchingActiveOperatorCount: payload?.isFetching,
      };
    }

    case UPDATE_USER_GROUPS: {
      const {
        id,
        groups,
        groupLeads,
      } = action.payload;
      const list = state.list.map((operator) => {
        if (operator.id === id || operator._id === id) {
          return {
            ...operator,
            groups: groups || operator.groups,
            groupLeads: groupLeads || operator.groupLeads,
          };
        }
        return operator;
      });

      return { ...state, list };
    }

    default:
      return state;
  }
};

export default commonReducer.reducer('OPERATOR', reducer);
