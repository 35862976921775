import { useState, useEffect } from 'react';

const useScreenSize = (): boolean => {
  const smallScreenWith = 768; // This matches Chakra UI's breakpoint for "md" screens
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth < smallScreenWith);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < smallScreenWith);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
};

export default useScreenSize;
