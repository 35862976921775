/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { lazyLoad } from '../../actions/common';
import { PAGE_SIZE } from '../../constants/general';

const withLazyLoad = (WrappedComponent, config) => class LazyLoad extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    offset: 0,
    ..._.get(config, 'defaultSort', { column: null, order: null }),
    disableInitialFetch: _.get(config, 'disableInitialFetch', false),
    query: _.get(config, 'query'),
    showAllUsers: config?.showAllUsers && config?.listLocation === 'users',
  };

  componentDidMount() {
    if (!this.state.disableInitialFetch) {
      this.getData();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
      // eslint-disable-line
    if (
      !_.isEqual(this.props.sort, newProps.sort)
        || !_.isEqual(this.props.filter, newProps.filter)
    ) {
      const { column, order } = _.get(newProps, 'sort', {});
      const { filter } = newProps;
      this.setState(
        {
          column: Array.isArray(column) ? _.first(column) : column,
          order,
          offset: 0,
          filter,
        },
        () => this.getData(),
      );
    }
  }

  modifyQuery = (changes, order = null, column = null) => {
    const { query } = this.state;
    const newQuery = { ...query, ...changes };

    // Remove null/undefined values from newQuery
    Object.keys(newQuery).forEach((key) => {
      if (newQuery[key] == null) {
        delete newQuery[key];
      }
    });

    this.setState(
      {
        offset: 0, order, column, query: newQuery,
      },
      this.getData,
    );
  };

  getData = (callback) => {
    const {
      offset, column, order, filter, query,
    } = this.state;
    const { type, noLimit } = config;

    let params = noLimit
      ? { offset, ...query, order }
      : {
        offset,
        limit: PAGE_SIZE,
        ...query,
        order,
      };

    if (column) {
      params = { ...params, sort: column, order };
    }
    if (filter) {
      params = { ...params, filter };
    }

    Object.keys(params).forEach((k) => {
      if (params[k] === null) {
        delete params[k];
      }
    });

    if (type) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.props.lazyLoad
          && this.props.lazyLoad(
            type,
            { ...params, ...(!this.state.showAllUsers && { active: true }) },
            _.get(config, 'listLocation'),
            callback,
          );
    }
  };

  increaseDataSet = (callback) => {
    const { offset } = this.state;
    this.setState({ offset: offset + PAGE_SIZE }, () => this.getData(callback));
  };

  render() {
    return (
      <WrappedComponent
        getData={this.getData}
        config={config}
        modifyQuery={this.modifyQuery}
        increaseDataSet={this.increaseDataSet}
        {...this.props}
      />
    );
  }
};

export default compose(
  connect(
    (state, ownProps) => {
      const listLocation = ownProps.type
        ? ownProps.type.stateLocation()
        : 'jobs';
      return {
        loadedItems: _.get(state, `${listLocation}.list`, []),
        loadedItemsById: _.get(state, `${listLocation}.listById`, {}),
        auth: _.get(state, 'auth'),
      };
    },
    { lazyLoad },
  ),
  withLazyLoad,
);
