import { defineStyleConfig } from '@chakra-ui/react';

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    margin: 0,
  },
});

export default headingTheme;
