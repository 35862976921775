import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import AssignUser from '@app/components/chakra/action-menus/assign-user';
import { type IFormInput } from '@app/types/create-case';

// Separate style objects for better organization
const formControlStyles = {
  '&:hover .chakra-menu__menu-button': {
    borderColor: 'gray.300',
  },
};

const menuStyles = {
  borderColor: 'gray.200',
  _hover: {
    bg: 'none',
    cursor: 'text',
  },
  _active: {
    bg: 'none',
    cursor: 'text',
    borderColor: 'blue.500 !important',
    borderWidth: '2px !important',
  },
};

const AssignUserMenu = () => {
  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext<IFormInput>();
  const operators = useSelector((state) => state.operators.list);

  register('assignedTo', {
    required: { value: true, message: 'You must select a user' },
  });

  const currentValue = getValues('assignedTo');

  const labelId = 'assignedTo-label';

  // Dynamic styles based on currentValue
  const dynamicMenuStyles = {
    ...menuStyles,
    color: currentValue ? 'black' : 'gray.500',
    fontSize: currentValue ? 'md' : 'sm',
  };

  return (
    <FormControl
      id={labelId}
      isInvalid={!!errors.assignedTo}
      sx={formControlStyles}
    >
      <FormLabel id={labelId}>Assignee (Required)</FormLabel>
      <AssignUser
        id="assignedToForm"
        defaultLabel="Choose Assignee"
        rowId="assignedToForm"
        selectedId={currentValue}
        handleSelect={(option) => {
          setValue('assignedTo', option);
          trigger('assignedTo');
          trigger('caseVisibility');
        }}
        options={operators}
        size="md"
        width="100%"
        aria-labelledby={labelId}
        sx={dynamicMenuStyles}
      />
      <FormErrorMessage>{errors.assignedTo?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default AssignUserMenu;
